import React from 'react'
import { DropdownType } from '../types/interface'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { cn } from '@did/tools'

export const Dropdown: DropdownType = ({
  children,
  options,
  onMenuClick,
  className,
  itemClassName,
  align = 'end'
}) => {
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <div>{children}</div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={cn(
            'max-w-full border border-gray-200 bg-white rounded-lg p-2 mt-2 shadow data-[side=bottom]:animate-fadeInY data-[state=closed]:animate-feadOutY z-20',
            className
          )}
          align={align}
        >
          {options?.map((o) => {
            if (o.type === 'separator') {
              return (
                <DropdownMenu.Separator
                  key={o.value}
                  className="h-[1px] bg-gray-200 my-2"
                />
              )
            }

            return (
              <DropdownMenu.Item
                key={o.value}
                className={cn(
                  itemClassName ||
                    'min-h-[44px] flex items-center rounded-lg hover:bg-gray-100 cursor-pointer px-3 outline-0 border-0'
                )}
                onClick={() => {
                  onMenuClick?.(o.value)
                }}
              >
                {o.label}
              </DropdownMenu.Item>
            )
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
