import { cn } from "@did/tools";

export function CheckStrongIcon(props: React.SVGProps<SVGSVGElement>) {
  const { className, ...rest } = props;

  return (
    <svg
      className={cn(className)}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.35352 15.1943C8.32878 15.1943 9.24707 15.0075 10.1084 14.6338C10.9697 14.2646 11.7262 13.752 12.3779 13.0957C13.0342 12.4395 13.5492 11.6829 13.9229 10.8262C14.2965 9.96484 14.4834 9.04427 14.4834 8.06445C14.4834 7.08919 14.2965 6.17318 13.9229 5.31641C13.5492 4.45508 13.0342 3.69629 12.3779 3.04004C11.7262 2.38379 10.9674 1.87109 10.1016 1.50195C9.24023 1.12826 8.32194 0.941406 7.34668 0.941406C6.37142 0.941406 5.45312 1.12826 4.5918 1.50195C3.73047 1.87109 2.97168 2.38379 2.31543 3.04004C1.66374 3.69629 1.15104 4.45508 0.777344 5.31641C0.408203 6.17318 0.223633 7.08919 0.223633 8.06445C0.223633 9.04427 0.408203 9.96484 0.777344 10.8262C1.15104 11.6829 1.66602 12.4395 2.32227 13.0957C2.97852 13.752 3.7373 14.2646 4.59863 14.6338C5.45996 15.0075 6.37826 15.1943 7.35352 15.1943ZM6.59473 11.4551C6.45345 11.4551 6.32585 11.4255 6.21191 11.3662C6.09798 11.3024 5.99089 11.2067 5.89062 11.0791L4.24316 9.09668C4.17936 9.01009 4.12923 8.92578 4.09277 8.84375C4.06087 8.75716 4.04492 8.66829 4.04492 8.57715C4.04492 8.38574 4.111 8.22396 4.24316 8.0918C4.37533 7.95508 4.53711 7.88672 4.72852 7.88672C4.84245 7.88672 4.94499 7.91178 5.03613 7.96191C5.12728 8.00749 5.21842 8.08724 5.30957 8.20117L6.57422 9.78711L9.3291 5.38477C9.49316 5.125 9.69596 4.99512 9.9375 4.99512C10.1198 4.99512 10.2816 5.05436 10.4229 5.17285C10.5687 5.29134 10.6416 5.44629 10.6416 5.6377C10.6416 5.72428 10.6234 5.81087 10.5869 5.89746C10.555 5.98405 10.514 6.06608 10.4639 6.14355L7.27148 11.0791C7.18945 11.1976 7.08919 11.291 6.9707 11.3594C6.85677 11.4232 6.73145 11.4551 6.59473 11.4551Z"
        fill="currentColor"
      />
    </svg>
  );
}
