import React from 'react'
import * as SelectBase from '@radix-ui/react-select'
import { SelectType } from '../types/interface'

const SelectItem = React.forwardRef<any, any>(
  ({ children, value, className, ...props }, forwardedRef) => {
    return (
      <SelectBase.Item
        className="min-h-[36px] hover:bg-gray-100 px-2 flex items-center cursor-pointer"
        {...props}
        ref={forwardedRef}
        value={value}
      >
        <SelectBase.ItemText>
          <>{children}</>
        </SelectBase.ItemText>
      </SelectBase.Item>
    )
  }
)

export const Select: SelectType = ({ children, options, onChange }) => {
  return (
    <SelectBase.Root onValueChange={onChange}>
      <SelectBase.Trigger className="w-full h-full outline-0">
        <SelectBase.Value asChild>{children}</SelectBase.Value>
      </SelectBase.Trigger>
      <SelectBase.Portal>
        <SelectBase.Content
          side="bottom"
          className="bg-white rounded-lg w-40 shadow data-[state=open]:animate-fadeInY data-[state=closed]:animate-feadOutY z-40"
        >
          <SelectBase.Viewport className="p-[5px]">
            {options?.map((o) => (
              <SelectItem key={o.value} value={o.value}>
                {o.label}
              </SelectItem>
            ))}
          </SelectBase.Viewport>
          <SelectBase.Arrow />
        </SelectBase.Content>
      </SelectBase.Portal>
    </SelectBase.Root>
  )
}
