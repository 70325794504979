import { cn } from "@did/tools";

export function LoadingIcon(props: React.SVGProps<SVGSVGElement>) {
  const { className, ...rest } = props;

  return (
    <svg
      className={cn("animate-spin", className)}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.66667C3.15905 1.66667 1.66667 3.15905 1.66667 5C1.66667 6.84095 3.15905 8.33333 5 8.33333C6.84095 8.33333 8.33333 6.84095 8.33333 5C8.33333 4.53976 8.70643 4.16667 9.16667 4.16667C9.6269 4.16667 10 4.53976 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C5.46024 0 5.83333 0.373096 5.83333 0.833333C5.83333 1.29357 5.46024 1.66667 5 1.66667Z"
        fill="currentColor"
      />
    </svg>
  );
}
