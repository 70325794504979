import { BaseCardType } from '@did/types/uikit'
import React, { useMemo } from 'react'
import { cn } from '@did/tools'

export const BaseCard: BaseCardType = ({
  children,
  className,
  status,
  style = {}
}) => {
  const styles = useMemo(() => {
    if (!status) return style
    if (status === 'warning') {
      return {
        background:
          'linear-gradient(180deg, #FFE8D3 0%, #FFFFFF 100%), #FFFFFF',
        backgroundClip: ' padding-box',
        ...style
      }
    }
  }, [status])

  return (
    <div
      className={cn(
        `border border-[#b6c4d966] rounded-2xl shadow-card   `,
        className,
        {
          ['bg-white']: !className?.includes('bg-')
        }
      )}
      style={styles}
    >
      {children}
    </div>
  )
}
