import { ErrorTypeEnum, ErrorType, isIgnoreEvent } from './error'
import errorMap from '@did/constants/errno'

const isFetchFailed = (event: any, hint: any) => {
  const message =
    hint.originalException?.message || event.exception?.values[0]?.value
  const messageList = [
    'Failed to fetch',
    'Load failed',
    'cancle',
    'Network Error'
  ]
  return !!messageList.find((v) => message.includes(v))
}

const isWalletBridgeError = (event: any, hint: any) => {
  // const message = hint.originalException?.message || event.exception?.values[0]?.value
  // switch (message) {
  //   case "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.":
  //     return true;
  // }
  return false
}

const isWalletConnectFetchFailed = (event: any, hint: any) => {
  const message =
    hint.originalException?.message || event.exception?.values[0]?.value
  const messageList = [
    'To use QR modal, please install @walletconnect/modal package',
    "undefined is not an object (evaluating 'newIcon.href')",
    'M is undefined',
    'WebAssembly.Compile is disallowed on the main thread, if the buffer size is larger than 4KB. Use WebAssembly.compile, or compile on a worker thread.'
  ]
  if (!!messageList.find((v) => message.includes(v))) {
    return true
  }

  if (
    /Verify iframe failed to load: https:\/\/verify.walletconnect.org/.test(
      message
    )
  ) {
    return true
  }

  const breadcrumbs = event.breadcrumbs
  if (!breadcrumbs) {
    return false
  }
  const isFetchFailed =
    breadcrumbs[breadcrumbs.length - 2]?.data?.method === 'GET' &&
    (/\/getDesktopListings\?/.test(
      breadcrumbs[breadcrumbs.length - 2]?.data?.url
    ) ||
      /api.tor.us\/latestversion\?name=@toruslabs\/torus-embed/.test(
        breadcrumbs[breadcrumbs.length - 2]?.data?.url
      ))

  return isFetchFailed
}

const appendAdditionalInfo = (event: any, hint: any) => {
  const error = hint.originalException
  event.extra ? (event.extra = {}) : ''
  switch (error?.code) {
    case errorMap.metaMaskWalletInvalidSignature:
      event.extra.ethereum = {
        ...(window as any).ethereum
      }
      break
  }
}

export const beforeSend = (event: any, hint: any) => {
  const error = hint.originalException
  if (typeof error === 'string' || error === null || error === undefined) {
    return null
  }
  if (typeof error === 'object' && error.type === undefined) {
    error.type = ErrorTypeEnum.CODE
  }

  if (
    isFetchFailed(event, hint) ||
    isWalletConnectFetchFailed(event, hint) ||
    isWalletBridgeError(event, hint)
  ) {
    return null
  }

  if (error && isIgnoreEvent(error)) {
    return null
  }

  if (error?.type === ErrorTypeEnum.REQUEST) {
    error.name = 'API Request Error'
    event.exception.values =
      event.exception.values?.map((v: any) => {
        v.type = error.name
        return v
      }) || []
  }

  event.message = error?.message
  event.tags = {
    ...(event.tags || {}),
    type: ErrorType[error?.type]
  }

  appendAdditionalInfo(event, hint)

  if (error?.code != undefined) {
    event.tags.error_code = error.code
  }
  console.log('send error event!!!')
  console.log(event)
  console.log(hint)
  return event
}
