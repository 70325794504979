import { CheckBoxType } from "@did/types/uikit";
import React from "react";
import { cn } from "@did/tools";

export const CheckBox: CheckBoxType = ({
  checked,
  onChange,
  children,
  className,
}) => {
  return (
    <div
      onClick={() => {
        onChange?.(!checked);
      }}
      className={cn(" cursor-pointer inline-flex items-center", className)}
    >
      <span
        className={cn(
          "w-5 h-5 flex items-center justify-center rounded  mr-2 box-border relative ",
          {
            ["bg-primary border-2 border-primary"]: checked,
            ["border-2 border-gray-200"]: !checked,
          },
        )}
      >
        {checked && (
          <span className=" w-1 h-2 border-r-2 border-b-2 border-white rotate-45 box-content -translate-y-px absolute "></span>
        )}
      </span>

      {children}
    </div>
  );
};
