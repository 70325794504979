import { TooltipType } from '../types/interface'
import * as TooltipBase from '@radix-ui/react-tooltip'
import styles from './styles.module.scss'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'

export const Tooltip: TooltipType = ({ children, message }) => {
  const [open, setOpen] = useState(false)

  return (
    <TooltipBase.Provider delayDuration={0}>
      <TooltipBase.Root
        open={open}
        onOpenChange={(open) => {
          !isMobile && setOpen(open)
        }}
      >
        <TooltipBase.Trigger asChild>
          <span
            onMouseEnter={() => {
              isMobile && setOpen(true)
            }}
            onMouseLeave={async () => {
              isMobile && setOpen(false)
            }}
          >
            {children}
          </span>
        </TooltipBase.Trigger>
        <TooltipBase.Portal>
          <TooltipBase.Content className={styles.TooltipContent} sideOffset={0}>
            {message}
            <TooltipBase.Arrow className={styles.TooltipArrow} />
          </TooltipBase.Content>
        </TooltipBase.Portal>
      </TooltipBase.Root>
    </TooltipBase.Provider>
  )
}
