import {
  IDasButtonProps,
  IDialogProps,
  ILangSwitcherProps,
  IConnectWalletProps,
  ITopNavProps,
  IIconItemProps,
  IButtonProps,
  IColorSearchInputProps,
  IBottomSheetProps,
  ICheckBoxProps
} from '@did/types/uikit'
import {
  Dialog as BaseDialog,
  DasButton as BaseDasButton,
  Button as BaseButton,
  Iconfont,
  Button as UikitButton,
  IconButton as BaseIconButton,
  PrimaryButton as BasePrimaryButton,
  Link,
  LangSelect as BaseLangSelect,
  LangSwitcher as BaseLangSwitcher,
  TopNav as BaseTopNav,
  TopNavMobile as BaseTopNavMobile,
  ConnectWallet as BaseConnectWallet,
  IconItem as BaseIconItem,
  Select,
  SearchInput as BaseSearchInput,
  BottomSheet as BaseBottomSheet,
  CheckBox as BaseCheckBox
} from './src'
import React from 'react'

export const DasButton = (props: Omit<IDasButtonProps, 'slots'>) => {
  return <BaseDasButton {...props} slots={{ icon: Iconfont }} />
}

export const Button = (props: Omit<IButtonProps, 'slots'>) => {
  return <BaseButton {...props} slots={{ icon: Iconfont }} />
}

export const PrimaryButton = (props: Omit<IButtonProps, 'slots'>) => {
  return <BasePrimaryButton {...props} slots={{ icon: Iconfont }} />
}

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  Omit<IButtonProps, 'slots'>
>((props: Omit<IButtonProps, 'slots'>, ref) => {
  return <BaseIconButton {...props} slots={{ icon: Iconfont }} ref={ref} />
})

export const Dialog = (props: Omit<IDialogProps, 'slots'>) => {
  return (
    <BaseDialog
      {...props}
      slots={{
        icon: Iconfont,
        button: DasButton
      }}
    />
  )
}

export const LangSwitcher = (props: Omit<ILangSwitcherProps, 'slots'>) => {
  return (
    <BaseLangSwitcher {...props} slots={{ icon: Iconfont, select: Select }} />
  )
}

export const LangSelect = (props: Omit<ILangSwitcherProps, 'slots'>) => {
  return (
    <BaseLangSelect {...props} slots={{ icon: Iconfont, select: Select }} />
  )
}

export const ConnectWallet = (props: Omit<IConnectWalletProps, 'slots'>) => {
  return (
    <BaseConnectWallet
      {...props}
      slots={{ icon: Iconfont, button: UikitButton as any }}
    />
  )
}

export const TopNav = (props: Omit<ITopNavProps, 'slots'>) => {
  return <BaseTopNav {...props} slots={{ link: Link, icon: Iconfont }} />
}

export const TopNavMobile = (props: Omit<ITopNavProps, 'slots'>) => {
  return <BaseTopNavMobile {...props} slots={{ link: Link, icon: Iconfont }} />
}

export const IconItem = (props: Omit<IIconItemProps, 'slots'>) => {
  return <BaseIconItem {...props} slots={{ icon: Iconfont }} />
}

export const SearchInput = (props: Omit<IColorSearchInputProps, 'slots'>) => {
  return (
    <BaseSearchInput
      {...props}
      slots={{ icon: Iconfont, button: UikitButton as any }}
    />
  )
}

export const BottomSheet = (props: Omit<IBottomSheetProps, 'slots'>) => {
  return (
    <BaseBottomSheet
      {...props}
      slots={{
        icon: Iconfont
      }}
    />
  )
}

export const CheckBox = (props: Omit<ICheckBoxProps, 'slots'>) => {
  return (
    <BaseCheckBox
      {...props}
      slots={{
        icon: Iconfont
      }}
    />
  )
}

export {
  DasAvatar,
  QRCode,
  openToast,
  openDialog,
  Dropdown,
  Select,
  Iconfont,
  Link,
  BaseCard,
  TextInput,
  IconImage,
  AboutFaq,
  PopUps,
  Tooltip,
  EllipsisText,
  // icon
  LoadingIcon,
  CloseIcon,
  PersonIcon,
  ArrowRightIcon,
  CheckStrongIcon,
  TabExplorerIcon,
  TabMeIcon,
  TabAliasIcon,
  DidCard,
  DidAvatar
} from './src'
