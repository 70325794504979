import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import styles from "./styles.module.scss";

export const openToast = (message: string, duration: number = 1000) => {
  const toastElement = document.createElement("div");

  const portal = ReactDOM.createPortal(
    <span className={styles.toast}>{message}</span>,
    toastElement,
  );

  document.body.appendChild(toastElement);
  const root = createRoot(toastElement);
  root.render(portal);

  setTimeout(() => {
    root.unmount();
    if (toastElement) {
      document.body.removeChild(toastElement);
    }
  }, duration);
};
