import { ignoreErrorCode } from '@did/constants/errno'

export interface IError {
  code: number
  message: string
  type?: ErrorTypeEnum
  level?: ErrorLevelEnum
}

export enum ErrorLevelEnum {
  DEBUG,
  INFO,
  WARNING,
  ERROR
}

export enum ErrorTypeEnum {
  REQUEST,
  CODE,
  UNEXPECTED
}

export const ErrorType: Record<any, string> = {
  [ErrorTypeEnum.REQUEST]: 'API',
  [ErrorTypeEnum.CODE]: 'CODE',
  [ErrorTypeEnum.UNEXPECTED]: 'UNEXPECTED'
}

export const ErrorLevel: Record<ErrorLevelEnum, string> = {
  [ErrorLevelEnum.DEBUG]: 'debug',
  [ErrorLevelEnum.INFO]: 'info',
  [ErrorLevelEnum.WARNING]: 'warning',
  [ErrorLevelEnum.ERROR]: 'error'
}

export class BaseError extends Error implements IError {
  code: number
  message: string
  level: ErrorLevelEnum
  type: ErrorTypeEnum

  constructor(
    code: number,
    message: string,
    level = ErrorLevelEnum.ERROR,
    type = ErrorTypeEnum.CODE
  ) {
    super(message)
    this.code = code
    this.message = message
    this.level = level
    this.type = type
  }
}

export type ErrorWhiteListType = Array<number | string>

const config: {
  errorWhiteList: ErrorWhiteListType
} = {
  errorWhiteList: ignoreErrorCode
}

export const setErrorWhiteList = (whiteList: ErrorWhiteListType) => {
  if (!Array.isArray(whiteList)) return
  config.errorWhiteList = config.errorWhiteList.concat(whiteList)
}

export const isIgnoreEvent = (error: BaseError) => {
  if (
    config.errorWhiteList.find(
      (codeOrMessage: any) =>
        error.code === codeOrMessage || error.message.includes(codeOrMessage)
    )
  ) {
    return true
  }
}
