// Dialog.tsx
import { DialogType, IDialogProps } from '@did/types/uikit'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

export const Dialog: DialogType = (param) => {
  const {
    showing = false,
    title = '',
    titleSize,
    message = '',
    actionButtonText,
    closeButton = false,
    enableCloseAction = false,
    onClose,
    slots,
    onAction,
    children,
    className,
    action,
    dialogClassname
  } = param
  const [containerLayoutMaxHeight, setContainerLayoutMaxHeight] = useState('')

  const ref = useRef<any>()
  const [mounted, setMounted] = useState(false)
  const isHaveShowRef = useRef(false)
  const [animationing, setAnimationing] = useState(false)

  const containerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    ref.current = document.getElementsByTagName('body')[0]
    setMounted(true)
  }, [])

  useEffect(() => {
    if (showing) {
      isHaveShowRef.current = true
    }
    if (isHaveShowRef.current) {
      containerRef.current?.classList.add('z-30')
      setAnimationing(true)
    }
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth
    const documentWidth = document.documentElement.offsetWidth
    const scrollBarWidth = viewportWidth - documentWidth
    setContainerLayoutMaxHeight(`${window.innerHeight * 0.9 - 92}px`)
    if (showing) {
      document.documentElement.style.overflowY = 'hidden'
      document.documentElement.style.paddingRight = `${scrollBarWidth}px`
    } else {
      document.documentElement.style.overflowY = 'auto'
      document.documentElement.style.paddingRight = '0px'
    }
    return () => {
      document.documentElement.style.overflowY = 'auto'
      document.documentElement.style.paddingRight = '0px'
    }
  }, [showing])

  const isMaskOn = useMemo(() => {
    return (
      showing ||
      animationing ||
      containerRef.current?.classList.contains('z-30')
    )
  }, [showing, animationing])

  // https://github.com/vercel/next.js/blob/canary/examples/with-portals/components/ClientOnlyPortal.js
  if (!mounted) return null

  return ReactDOM.createPortal(
    <div
      ref={(r) => (containerRef.current = r!)}
      onAnimationEnd={(e) => {
        if (!showing) {
          e.currentTarget.classList.remove('z-30')
        }
        setAnimationing(false)
      }}
      className={`fixed top-0 left-0 flex justify-center cursor-default h-full w-screen -z-30 ${className} ${
        showing
          ? 'animate-dialogShow'
          : isHaveShowRef.current
            ? 'animate-dialogHide'
            : ''
      }`}
    >
      <div
        className={`relative max-w-[394px] w-11/12 self-center rounded-3xl overflow-hidden bg-white ${dialogClassname}`}
      >
        {isMaskOn && (
          <>
            <div className="flex justify-between items-center p-8">
              <span
                style={titleSize ? { fontSize: `${titleSize}px` } : {}}
                className="w-84 overflow-hidden overflow-ellipsis whitespace-nowrap text-2xl font-medium text-black text-left leading-8"
              >
                {title}
              </span>
              {closeButton && (
                <span
                  className="hover:bg-gray-100 rounded-full cursor-pointer absolute right-6 top-7 p-2 leading-4 "
                  onClick={onClose}
                >
                  {slots.icon && (
                    <slots.icon name="close" size="19" color="#A0A1AB" />
                  )}
                </span>
              )}
            </div>
            <div
              className="overflow-y-auto scrollbar-none"
              style={{ maxHeight: containerLayoutMaxHeight }}
            >
              {message && (
                <div className="px-8 text-sm text-gray-800 break-words leading-5">
                  {message}
                </div>
              )}
              {children && (
                <div className="px-8 text-sm text-gray-700 break-words leading-5">
                  {children}
                </div>
              )}
              {action && <>{action}</>}
              {enableCloseAction && (
                <div className="p-8">
                  <slots.button
                    slots={{ icon: slots.icon }}
                    block
                    black
                    isLoadingGradient={false}
                    onClick={() => {
                      if (onAction) {
                        onAction()
                        return
                      }
                      onClose?.()
                    }}
                  >
                    {actionButtonText || 'OK'}
                  </slots.button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>,
    ref.current
  )
}

export const openDialog = (param: IDialogProps) => {
  const toastElement = document.createElement('div')
  document.body.appendChild(toastElement)
  const root = createRoot(toastElement)
  const onClose = () => {
    // isOpen = false;
    root.unmount()
    if (toastElement) {
      document.body.removeChild(toastElement)
    }
  }

  const DelayDialog = () => {
    const [showing, setShowing] = useState(false)
    useEffect(() => {
      setTimeout(() => {
        // isOpen = true;
        setShowing(true)
      }, 100)
    }, [])

    return <Dialog showing={showing} closeButton {...param} onClose={onClose} />
  }

  const portal = ReactDOM.createPortal(<DelayDialog />, toastElement)

  root.render(portal)

  return onClose
}
