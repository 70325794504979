import { cn } from '@did/tools'

export function TabExplorerIcon(props: React.SVGProps<SVGSVGElement>) {
  const { className, ...rest } = props

  return (
    <svg
      className={cn(className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        data-follow-fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.301 4.378A4.982 4.982 0 0 0 20.87.744V.522H10.435l.011.011C4.632.8 0 5.598 0 11.478c0 6.051 4.905 10.957 10.957 10.957 2.633 0 5.05-.93 6.94-2.478l2.986 2.986.172.152a1.826 1.826 0 0 0 2.41-2.734l-3.162-3.162a10.905 10.905 0 0 0 1.61-5.72c0-2.71-.983-5.189-2.612-7.101Zm-1.562 7.1a6.783 6.783 0 1 0-13.565 0 6.783 6.783 0 0 0 13.565 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
