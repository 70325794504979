import {
  getCoinTypeToChainIdMap,
  EvmCoinTypes,
  CoinType,
  CKB,
  DOGE,
  ETH,
  TRON
} from '@did/constants/chain'
import { scriptToAddress } from '@nervosnetwork/ckb-sdk-utils'
import Base58 from 'bs58'
import { addressValidate } from './index'

const getTronFullAddress = async (
  connectedAccountAddress: string,
  isProdData: boolean = true
) => {
  if (!window) return
  return new Promise((resolve, reject) => {
    let cycles = 0
    const tronWebInterval = setInterval(() => {
      if (cycles > 10) {
        clearInterval(tronWebInterval)
        resolve('')
      }
      cycles = cycles + 1
      const { tronWeb } = window as any
      if (typeof tronWeb !== 'undefined') {
        clearInterval(tronWebInterval)
        try {
          let address = tronWeb.defaultAddress.hex?.toLocaleLowerCase()
          address = address.slice(2)
          const script = {
            args: '0x04' + address + '04' + address,
            code_hash: isProdData
              ? '0x9376c3b5811942960a846691e16e477cf43d7c7fa654067c9948dfcd09a32137'
              : '0x326df166e3f0a900a0aee043e31a4dea0f01ea3307e6e235f09d1b4220b75fbd',
            hash_type: 'type'
          }
          const _fulladdress = scriptToAddress(
            {
              codeHash: script.code_hash,
              // @ts-ignore
              hashType: script.hash_type,
              args: script.args
            },
            isProdData
          )
          resolve(_fulladdress)
        } catch (err) {
          resolve('')
        }
      }
    }, 200)
  })
}

const getEthFullAddress = (
  connectedAccountAddress: string,
  isProdData: boolean = true
) => {
  let address = connectedAccountAddress?.toLocaleLowerCase()
  address = address.slice(2)
  const script = {
    args: '0x03' + address + '03' + address,
    code_hash: isProdData
      ? '0x9376c3b5811942960a846691e16e477cf43d7c7fa654067c9948dfcd09a32137'
      : '0x326df166e3f0a900a0aee043e31a4dea0f01ea3307e6e235f09d1b4220b75fbd',
    hash_type: 'type'
  }
  return scriptToAddress(
    {
      codeHash: script.code_hash,
      // @ts-ignore
      hashType: script.hash_type,
      args: script.args
    },
    isProdData
  )
}

const getDogeFullAddress = (
  connectedAccountAddress: string,
  isProdData: boolean = true
) => {
  let address = connectedAccountAddress
  if (!address) return
  const decodeBuffer = Buffer.from(Base58.decode(address))
  address = decodeBuffer
    .slice(0, decodeBuffer.byteLength - 4)
    .slice(1)
    .toString('hex')
  const script = {
    args: '0x07' + address + '07' + address,
    code_hash: isProdData
      ? '0x9376c3b5811942960a846691e16e477cf43d7c7fa654067c9948dfcd09a32137'
      : '0x326df166e3f0a900a0aee043e31a4dea0f01ea3307e6e235f09d1b4220b75fbd',
    hash_type: 'type'
  }
  return scriptToAddress(
    {
      codeHash: script.code_hash,
      // @ts-ignore
      hashType: script.hash_type,
      args: script.args
    },
    isProdData
  )
}

export const calcAccount = async (param: {
  connectedAccountInfo: any
  isProdData: boolean
}) => {
  const { connectedAccountInfo, isProdData = true } = param
  if (!connectedAccountInfo) return connectedAccountInfo
  const coinType = connectedAccountInfo?.chain?.coinType

  if (!coinType || !connectedAccountInfo?.address) {
    return connectedAccountInfo
  }

  let ckbAddress: any

  if (
    coinType === CoinType.trx &&
    addressValidate(connectedAccountInfo.address, TRON.symbol, isProdData)
  ) {
    const _fullCkbAddress = await getTronFullAddress(
      connectedAccountInfo.address,
      isProdData
    )
    ckbAddress = {
      address: connectedAccountInfo.address,
      fullCkbAddress: _fullCkbAddress
    }
  } else if (
    EvmCoinTypes.includes(coinType) &&
    addressValidate(connectedAccountInfo.address, ETH.symbol, isProdData)
  ) {
    const _fullCkbAddress = await getEthFullAddress(
      connectedAccountInfo.address,
      isProdData
    )

    ckbAddress = {
      address: connectedAccountInfo.address,
      fullCkbAddress: _fullCkbAddress
    }
  } else if (
    CoinType.doge === coinType &&
    addressValidate(connectedAccountInfo.address, DOGE.symbol, isProdData)
  ) {
    const _fullCkbAddress = await getDogeFullAddress(
      connectedAccountInfo.address,
      isProdData
    )
    ckbAddress = {
      address: connectedAccountInfo.address,
      fullCkbAddress: _fullCkbAddress
    }
  } else if (
    coinType === CoinType.ckb &&
    addressValidate(connectedAccountInfo.address, CKB.symbol, isProdData)
  ) {
    ckbAddress = {
      address: connectedAccountInfo.address,
      fullCkbAddress: connectedAccountInfo.address
    }
  }

  connectedAccountInfo.ckbAddress = ckbAddress
  connectedAccountInfo.computedChainId =
    getCoinTypeToChainIdMap(isProdData)[coinType]
  return connectedAccountInfo
}
