import { ILanguageOption, LangSwitcherType, LANGUAGE } from '@did/types/uikit'
import React, { useState, useMemo } from 'react'
import styles from './style.module.scss'

export const LangSelect: LangSwitcherType = ({
  dark = false,
  slots,
  computedLanguage,
  switchLanguage,
  className,
  langList = []
}) => {
  const [optionsShowing, setOptionsShowing] = useState(false)

  const onShowOptions = () => {
    setOptionsShowing(!optionsShowing)
  }

  const langOption = useMemo(() => {
    let options: ILanguageOption[] = [...langList]
    if (computedLanguage) {
      options = [
        computedLanguage,
        ...langList.filter((l) => l.value !== computedLanguage.value)
      ]
    }

    return options.map((l) => ({
      label: (
        <div key={l?.value} className="flex items-center text-sm font-bold">
          <slots.icon
            className="mr-3"
            name={l?.value === LANGUAGE.en ? 'en' : 'cn'}
            size="18"
            color={dark ? '#FFFFFF' : '#121314'}
          />
          {l?.text}
        </div>
      ),
      value: l.value
    }))
  }, [computedLanguage])

  return (
    <div className={`w-full ${className} `}>
      <slots.select
        options={langOption}
        onChange={(v) => {
          switchLanguage?.(langList.find((l) => l.value === v)!)
        }}
      >
        <div
          className="w-full flex justify-between items-center"
          onClick={onShowOptions}
        >
          <div className="flex items-center text-sm font-bold">
            <slots.icon
              className="mr-3"
              name={computedLanguage?.value === LANGUAGE.en ? 'en' : 'cn'}
              size="18"
              color={dark ? '#FFFFFF' : '#121314'}
            />
            {computedLanguage?.text}
          </div>

          <slots.icon
            className={styles.langSwitcher__language__arrowDown}
            name="toggle"
            size="10"
            color={dark ? '#FFFFFF' : '#5F6570'}
          />
        </div>
      </slots.select>
    </div>
  )
}
