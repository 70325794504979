import { DasButtonType } from '@did/types/uikit'
import React from 'react'
import styles from './styles.module.scss'

export const DasButton: DasButtonType = ({
  block = false,
  loading = false,
  defaultLoading = false,
  isLoadingGradient = true,
  done = false,
  primary = false,
  black = false,
  second = false,
  status,
  // status = "default",
  solid = false,
  small = false,
  middle = false,
  large = false,
  to = '',
  href = '',
  target = '_self',
  disabled = false,
  children,
  slots,
  className,
  onClick,
  ...others
}) => {
  const classes = [
    styles.button,
    block ? styles.button__block : '',
    primary ? styles.button__type__primary : '',
    black ? styles.button__type__black : '',
    solid ? styles.button__type__solid : '',
    small ? styles.button__size__small : '',
    middle ? styles.button__size__middle : '',
    large ? styles.button__size__large : '',
    status ? styles[`button__status__${status}`] : '',
    to || href ? styles.button__type__link : '',
    loading && isLoadingGradient ? styles.button__loading : '',
    !second && loading && !isLoadingGradient
      ? styles.button__loading_no_gradient
      : '',
    defaultLoading ? styles.button__default_loading : '',
    done ? styles.button__done : '',
    className || ''
  ].join(' ')

  return (
    <button
      className={classes}
      disabled={disabled || loading}
      onClick={onClick}
      {...others}
    >
      {defaultLoading && (
        <span
          className={`${styles.button__loadingIcon} ${
            children ? styles.button__loadingIcon__marginRight8 : ''
          }`}
        >
          <slots.icon name="loading" color="rgba(255, 255, 255, 0.6)" />
        </span>
      )}
      {loading && isLoadingGradient && (
        <span
          className={`${styles.loading} ${
            children ? styles.button__loadingIcon__marginRight8 : ''
          } `}
        >
          <div
            className="w-7 h-7 rounded-full flex justify-center items-center "
            style={{
              background:
                'conic-gradient(from 180deg at 50% 50%, #2EB67D -100.12deg, rgba(46, 182, 125, 0) 193.33deg, #2EB67D 259.88deg, rgba(46, 182, 125, 0) 553.33deg)'
            }}
          >
            <div className="w-5 h-5 bg-[#d6efe7] rounded-full"></div>
          </div>
        </span>
      )}
      {loading && !isLoadingGradient && (
        <span className="mr-1">
          <slots.icon className="animate-spin" name="loading" />
        </span>
      )}
      {done && (
        <div>
          <slots.icon name="check-strong" color="rgb(13, 186, 133)" />
        </div>
      )}
      {to ? (
        <a className={styles.button__link} href={to}>
          {children}
        </a>
      ) : href ? (
        <a className={styles.button__link} href={href} target={target}>
          {children}
        </a>
      ) : (
        children
      )}
    </button>
  )
}
