import * as SentryModule from '@sentry/nextjs'
import { beforeSend } from './send-filter'
import { setErrorWhiteList, ErrorWhiteListType } from './error'
import { IError, ErrorTypeEnum, ErrorType, ErrorLevelEnum } from './error'
// import { errorInfo as ErrorInfo } from "./error-info";

export { errorInfo as ErrorInfo } from './error-info'
export * from './error'

export interface IIssue {
  level?: SentryModule.SeverityLevel
  issueType: ErrorTypeEnum
  extra?: Record<string, any>
}

// export interface IMessage extends IIssue {
//   message: string;
// }

export interface IException extends IIssue {
  exception: IError
}

export interface IMonitoringConfig extends SentryModule.BrowserOptions {
  config?: {
    errorWhiteList?: ErrorWhiteListType
  }
}

export const init = (monitoringConfig: IMonitoringConfig) => {
  const { release, config = { errorWhiteList: [] } } = monitoringConfig

  setErrorWhiteList(config.errorWhiteList || [])

  SentryModule.init({
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ...monitoringConfig,
    release: release || process.env.npm_package_version,
    beforeSend
  })
}

// export const captureMessage = (params: IMessage) => {
//   const { message, level = "error", issueType = ErrorTypeEnum.REQUEST, extra } = params;
//   SentryModule.captureMessage(message, {
//     level,
//     extra,
//     tags: {
//       type: ErrorType[issueType]
//     }
//   })
// }

export const captureException = (params: IException) => {
  const {
    exception,
    level = 'error',
    issueType = ErrorTypeEnum.REQUEST,
    extra
  } = params
  SentryModule.captureException(exception, {
    level,
    extra,
    tags: {
      type: ErrorType[issueType]
    }
  })
}

// export const responseSuccess = (callback: ((data: any) => any) | undefined) => {
//   return (params: any): any => {
//     try {
//       return callback?.(params)
//     } catch (error: any) {
//       ErrorInfo.error({
//         code: error.code,
//         message: error.message,
//         level: ErrorLevelEnum.ERROR,
//         type: ErrorTypeEnum.REQUEST
//       })
//     }
//   }
// }

// export const responseFailed = (callback: ((data: any) => any) | undefined) => {
//   return (params: any): any => {
//     try {
//       return callback?.(params)
//     } catch (error: any) {
//       ErrorInfo.error({
//         code: error.code,
//         message: error.message,
//         level: ErrorLevelEnum.ERROR,
//         type: ErrorTypeEnum.REQUEST
//       })
//     }
//   }
// }

export const Sentry = SentryModule

export const withSentryConfig = SentryModule.withSentryConfig
