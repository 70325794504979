import { TopNavType } from '@did/types/uikit'
import styles from './top-nav.module.scss'
import React from 'react'
import { cn } from '@did/tools'

export const TopNavMobile: TopNavType = ({
  slots,
  Right,
  Logo,
  className,
  style,
  menus,
  currPath,
  handleMenuClick
}) => {
  return (
    <>
      <div
        className={cn(
          styles.topNavMobile,
          'flex z-10 sticky top-0 left-0 min-h-[62px] h-[62px] px-4',
          className
        )}
        style={style}
      >
        {Logo}
        <Right className={styles.topNavMobile__langSwitcher} />
      </div>
      {Boolean(menus?.length) && (
        <nav
          className="lg:hidden flex fixed justify-between bottom-0 left-0 items-center w-full bg-white/90 border-t border-slate-600/10 backdrop-blur-[10px]"
          style={{
            height: 'calc(52px + env(safe-area-inset-bottom))'
          }}
        >
          {menus?.map((menu, index) =>
            menu.path ? (
              <slots.link
                key={index}
                className={cn(
                  'px-5 text-[10px] font-semibold inline-flex items-center flex-col w-full',
                  currPath?.includes(menu.path)
                    ? 'text-emerald-400'
                    : 'text-zinc-500'
                )}
                handleClick={() => {
                  handleMenuClick?.(menu?.path)
                }}
              >
                {menu.iconName ? (
                  <slots.icon
                    size={20}
                    name={menu.iconName}
                    className={cn(
                      currPath?.includes(menu.path)
                        ? ''
                        : 'text-third-font-color'
                    )}
                  />
                ) : menu.iconComponent ? (
                  <menu.iconComponent
                    className={cn(
                      currPath?.includes(menu.path)
                        ? ''
                        : 'text-third-font-color',
                      'w-5 h-5'
                    )}
                  />
                ) : null}
                <span className="px-3 text-center mt-1">{menu.text}</span>
              </slots.link>
            ) : (
              <a
                key={index}
                className={cn(
                  'px-5 text-[10px] font-semibold inline-flex items-center flex-col w-full text-zinc-500'
                )}
                target="_self"
                href={menu.href}
              >
                {menu.iconName ? (
                  <slots.icon
                    size={20}
                    name={menu.iconName}
                    className="text-third-font-color"
                  />
                ) : menu.iconComponent ? (
                  <menu.iconComponent className="text-third-font-color w-5 h-5" />
                ) : null}
                <span className="px-3 text-center mt-1">{menu.text}</span>
              </a>
            )
          )}
        </nav>
      )}
    </>
  )
}
