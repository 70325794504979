import React, { useCallback, useState } from 'react'
import {
  Elements,
  PaymentElement,
  PaymentElementProps,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { useStripeContext } from '../stripe-context'
import { StripeError, StripeElementsOptions } from '@stripe/stripe-js'
import { useForm } from 'react-hook-form'
import { StripeInput } from './stripe-input'
import { DasButton, Iconfont } from '@did/uikit'
import { IContext } from '@did/types/das-context'
import { ICallback } from '@did/types'
import { cn, ErrorInfo, IError } from '@did/tools'
import { captureException, ErrorTypeEnum, BaseError } from '@did/monitoring'

export interface IPaymentProps extends ICallback<any, any> {
  stripeElementOption: StripeElementsOptions
  stripePaymentOption: PaymentElementProps
  alert: IContext['alert']
  tt: IContext['tt']
  returnURL: string
  successCallback?: (event: any) => any
  onLoadError?: (event: StripeError) => void
}

interface IFormValues {
  username: string
  email: string
}

export const PaymentForm: React.FC<IPaymentProps> = ({
  stripeElementOption,
  stripePaymentOption,
  alert,
  tt,
  returnURL,
  successCallback,
  onLoadError
}) => {
  const [payLoading, setPayLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormValues>({
    mode: 'all'
  })

  const onSubmit = async (param: IFormValues) => {
    if (!stripe || !elements) {
      return
    }
    try {
      setPayLoading(true)
      const submitError = await elements.submit()

      if (submitError?.error) {
        if (
          !['card_error', 'validation_error'].includes(submitError.error.type)
        ) {
          alert({
            title: tt('Error'),
            message: `${submitError.error.code}: ${submitError.error.message}`
          })
        }
        onLoadError?.(submitError.error)
        return
      }

      const { error } = await stripe.confirmPayment({
        elements: elements,
        clientSecret: stripeElementOption.clientSecret!,
        confirmParams: {
          receipt_email: param.email,
          return_url: returnURL,
          payment_method_data: {
            billing_details: {
              name: param.username
            }
          }
        },
        redirect: 'if_required'
      })

      if (error) {
        ErrorInfo.error(error as unknown as IError)
        alert({
          title: tt('Error'),
          message: `${error.code}: ${error.message}`
        })
        onLoadError?.(error)
        return
      }
      successCallback?.({})
    } catch (err: any) {
      ErrorInfo.error(err)
      alert({
        title: tt('Error'),
        message: `${err.code}: ${err.message}`
      })
    } finally {
      setPayLoading(false)
    }
  }

  const onLoadStripeError = useCallback(
    (event: { elementType: string; error: StripeError }) => {
      console.log(event)
      captureException({
        exception: event.error as unknown as IError,
        issueType: ErrorTypeEnum.CODE
      })
      onLoadError?.(event?.error)
    },
    []
  )

  return (
    <div>
      <form
        className={cn({
          ['hidden']: loading,
          ['block']: !loading
        })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <StripeInput
          type="email"
          label="Email"
          placeholder="Please enter Email"
          errorMessages={[errors?.email?.message || ''].filter(Boolean)}
          {...register('email', {
            required: {
              value: true,
              message: 'Invalid Email'
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Email format error'
            }
          })}
        />
        <StripeInput
          className=" mt-3 mb-3"
          type="text"
          label="Card holder name"
          placeholder="Please enter card holder name"
          errorMessages={[errors?.username?.message || ''].filter(Boolean)}
          {...register('username', {
            required: {
              value: true,
              message: 'Invalid card holder name'
            }
          })}
        />

        <PaymentElement
          {...stripePaymentOption}
          onReady={() => {
            setLoading(false)
          }}
          onLoadError={onLoadStripeError}
        />
        <DasButton
          black
          block
          className=" mt-8"
          loading={payLoading}
          isLoadingGradient={false}
        >
          Pay
        </DasButton>
      </form>
      <div
        className={cn('h-96 items-center justify-center', {
          ['hidden']: !loading,
          ['flex']: loading
        })}
      >
        <Iconfont
          className=" animate-spin"
          name="loading"
          color="#22C493"
          size="58"
        />
      </div>
      <div className=" flex text-xs font-medium text-gray-500 justify-center items-center">
        Powered by
        <Iconfont
          className="ml-1"
          name="stripe-text"
          size="34"
          color="#8792A2"
        />
      </div>
    </div>
  )
}

export const Payment: React.FC<IPaymentProps> = (props) => {
  const { stripePromise } = useStripeContext()
  const [updateNumber, setUpdateNumber] = useState(1)

  if (!props?.stripeElementOption.clientSecret) {
    const error = new BaseError(
      90001,
      'props?.stripeElementOption.clientSecret is null'
    )
    captureException({
      exception: error,
      issueType: ErrorTypeEnum.CODE
    })
    return <></>
  }

  const onLoadError = useCallback((error: StripeError) => {
    setUpdateNumber((value: number) => value++)
    props?.onLoadError?.(error)
  }, [])

  return (
    updateNumber && (
      <Elements stripe={stripePromise} options={props.stripeElementOption}>
        <PaymentForm {...props} onLoadError={onLoadError} />
      </Elements>
    )
  )
}
