import React from "react";
import QRCodeBase from "react-qr-code";
import { QrCodeType } from "../types/interface";

export const QRCode: QrCodeType = (props) => {
  const { value = "", className } = props;
  return (
    <div className={className}>
      <QRCodeBase value={value} size={138} />
    </div>
  );
};
