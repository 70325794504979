import { cn } from "@did/tools";

export function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  const { className, ...rest } = props;

  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="24" height="24" rx="12" fill="#20232D" fillOpacity="0.1" />
      <path
        d="M7.31038 16.6896C7.17243 16.5477 7.07981 16.3822 7.03252 16.193C6.98916 16.0038 6.98916 15.8166 7.03252 15.6314C7.07981 15.4462 7.16849 15.2885 7.29855 15.1584L10.4555 11.9956L7.29855 8.84452C7.16849 8.71445 7.08178 8.5568 7.03843 8.37156C6.99507 8.18238 6.99507 7.9932 7.03843 7.80402C7.08178 7.61484 7.17243 7.45128 7.31038 7.31333C7.44832 7.17145 7.61188 7.07883 7.80106 7.03547C7.99419 6.99212 8.18337 6.99212 8.36861 7.03547C8.55779 7.07883 8.71741 7.16553 8.84747 7.2956L12.0044 10.4466L15.1555 7.30151C15.2895 7.1675 15.4491 7.07883 15.6343 7.03547C15.8196 6.98818 16.0048 6.98818 16.1901 7.03547C16.3792 7.08277 16.5428 7.17736 16.6808 7.31924C16.8226 7.45719 16.9172 7.61878 16.9645 7.80402C17.0118 7.98926 17.0118 8.17647 16.9645 8.36565C16.9212 8.55089 16.8325 8.71051 16.6985 8.84452L13.5534 11.9956L16.6985 15.1525C16.8325 15.2865 16.9212 15.4481 16.9645 15.6373C17.0118 15.8225 17.0118 16.0098 16.9645 16.1989C16.9172 16.3842 16.8226 16.5458 16.6808 16.6837C16.5428 16.8217 16.3792 16.9143 16.1901 16.9616C16.0048 17.0089 15.8196 17.0108 15.6343 16.9675C15.4491 16.9241 15.2895 16.8335 15.1555 16.6955L12.0044 13.5445L8.84747 16.7014C8.71741 16.8315 8.55779 16.9182 8.36861 16.9616C8.18337 17.0089 7.99616 17.0089 7.80698 16.9616C7.61779 16.9182 7.45226 16.8276 7.31038 16.6896Z"
        fill="currentColor"
      />
    </svg>
  );
}
