import React from 'react'
import { ButtonType, IButtonProps } from '@did/types/uikit'
import { cn } from '@did/tools'
export const Button: ButtonType = ({
  children,
  className,
  onClick,
  disabled,
  type,
  size = 'middle'
}) => {
  if (type === 'text') {
    return (
      <button
        className={cn(
          'px-2 flex justify-center items-center cursor-pointer hover:bg-gray-100',
          className,
          {
            ['text-sm font-semibold rounded h-8']: size === 'small',
            ['h-9 rounded-lg']: size === 'small'
          }
        )}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }

  return (
    <button
      className={cn(
        `border px-2 h-9 flex justify-center items-center cursor-pointer ${className} `,
        {
          ['bg-white border-gray-100 hover:bg-gray-100 active:bg-gray-200']:
            !className?.includes('bg-'),
          ['rounded-lg']: !className?.includes('rounded'),
          ['bg-opacity-70 cursor-no-drop']: disabled
        }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export const PrimaryButton: ButtonType = React.forwardRef<
  HTMLButtonElement,
  IButtonProps
>(
  (
    {
      children,
      className,
      onClick,
      disabled,
      status = 'default',
      size = 'middle',
      full = true,
      slots
    },
    ref
  ) => {
    if (status === 'success') {
      return (
        <button
          className={cn(
            ` text-base px-5 font-bold bg-pri-disabled  text-primary flex items-center justify-center rounded-3xl h-11  ${className} `,
            { ['h-[52px]']: size === 'larger' }
          )}
          onClick={onClick}
          disabled={disabled}
        >
          <slots.icon
            name="check-strong"
            className="mr-2"
            size={32}
            color="#22c493"
          />
          {children}
        </button>
      )
    }

    if (status === 'loading') {
      return (
        <button
          className={cn(
            `text-base px-5 font-bold bg-pri-disabled text-primary flex items-center   rounded-3xl h-11  ${className} `,
            { ['h-[52px]']: size === 'larger' }
          )}
          onClick={onClick}
          disabled={disabled}
        >
          <span className="animate-spin mr-2">
            <div
              className="w-7 h-7 rounded-full flex justify-center items-center "
              style={{
                background:
                  'conic-gradient(from 180deg at 50% 50%, #2EB67D -100.12deg, rgba(46, 182, 125, 0) 193.33deg, #2EB67D 259.88deg, rgba(46, 182, 125, 0) 553.33deg)'
              }}
            >
              <div className=" w-5 h-5 bg-[#d6efe7]   rounded-full  "></div>
            </div>
          </span>
          {children}
        </button>
      )
    }

    return (
      <button
        ref={ref}
        className={cn(
          ` text-base px-5 rounded-3xl h-11 font-medium ${className}`,
          {
            ['bg-gray-200 text-gray-500']: status === 'gray',
            ['bg-primary  text-white ']: status !== 'gray',
            ['hover:bg-pri-hover']: !disabled && status !== 'gray',
            ['opacity-60 cursor-not-allowed']: disabled,
            ['h-[52px]']: size === 'larger',
            ['w-full']: full
          }
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    )
  }
)
