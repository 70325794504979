import Decimal from 'decimal.js'
// @ts-expect-error
import abcCopy from 'abc-copy'
import dayjs, { Dayjs } from 'dayjs'
import {
  DIDPOINT_ACTIONS,
  TIME_FORMAT,
  TOKEN_DECIMAL_PLACES,
  TwitterUserUrlRegExp
} from '@did/constants'
import {
  BSC,
  CKB,
  CoinType,
  DWEB_KEY_OPTIONS,
  ETH,
  ICoinTypeInfo,
  PARSING_RECORD_SUPPORT_CHAINS,
  Polygon,
  PROFILE_KEY_OPTIONS
} from '@did/constants/chain'
import { validate } from 'multicoin-address-validator'
import GraphemeSplitter from 'grapheme-splitter'
import lodashMerge from 'lodash.merge'
import lodashDebounce from 'lodash.debounce'
import confetti from 'canvas-confetti'
import { ErrorInfo } from '@did/monitoring'
import { Keccak } from 'sha3'
import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { ParsingRecordDwebKey, ParsingRecordProfileKey } from '@did/types'
import CID from 'cids'
import isValidDomain from 'is-valid-domain'

export * from '@did/monitoring'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Used to determine whether it is a mobile terminal
 */
export function isMobile(breakpoint?: any): boolean {
  if (typeof breakpoint !== 'number') breakpoint = 768

  return globalThis?.window?.innerWidth < breakpoint
}

/**
 * open link in different ways depending on the device.
 * @param link
 */
export function smartOpen(link: string) {
  if (isMobile()) {
    window.location.href = link
  } else {
    window.open(link)
  }
}

/**
 * shrinkUnit
 * @param value
 * @param shrinkDecimals
 * @param precision
 */
export function shrinkUnit(
  value: string | number | Decimal,
  shrinkDecimals: number,
  precision = 8
): string {
  value = value || 0
  const decimalNum = Decimal.div(value, 10 ** shrinkDecimals)
  let decimals = decimalNum.decimalPlaces()

  if (decimals > precision) {
    decimals = precision
  }
  return decimalNum.toFixed(decimals, Decimal.ROUND_DOWN)
}

export function fromSatoshi(satoshi: string): string {
  return shrinkUnit(satoshi, CKB.decimals, TOKEN_DECIMAL_PLACES)
}

/**
 * expandUnit
 * @param value
 * @param decimals
 * @param precision
 */
export function expandUnit(
  value: string | number | Decimal,
  decimals: number,
  precision = 0
): string {
  value = value || 0
  return Decimal.mul(value, 10 ** decimals).toFixed(
    precision,
    Decimal.ROUND_DOWN
  )
}

/**
 * Thousands of divisions
 * @param num
 * @param digits
 */
export function thousandSplit(
  num: number | string | Decimal = 0,
  digits?: number
): string {
  if (digits && digits > 0) {
    if (Decimal.isDecimal(num)) {
      num = (num as Decimal).toFixed(digits).toString()
    } else {
      num = new Decimal(num).toFixed(digits).toString()
    }
  }
  const strNum = num + ''
  if (strNum.includes('.')) {
    return (num + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  } else {
    return strNum.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }
}

/**
 * Copy data to the clipboard
 * @param text
 * @param el
 */
export function copyText(text: string, el?: Element): Promise<void> {
  return abcCopy(text, {
    target: el
  })
}

/**
 * String reduction
 * @param inputString
 * @param head
 * @param tail
 * @param tokenStr
 */
export function collapseString(
  inputString = '',
  head = 4,
  tail = 4,
  tokenStr = '...'
): string {
  const splitter = new GraphemeSplitter()
  const split = splitter.splitGraphemes(inputString)
  if (split.length > 12) {
    return (
      split.slice(0, head).join('') +
      tokenStr +
      split.slice(split.length - tail, split.length).join('')
    )
  } else {
    return inputString
  }
}

/**
 * Handling dayjs format not supported in safari 2019-07-02T13:34:11+0000
 * @param timestamp
 */
export function safariTimestampFormat(
  timestamp: string | number | Dayjs
): string | number | Dayjs {
  if (typeof timestamp === 'string') {
    return timestamp.replace(/\+0000/, 'Z')
  } else {
    return timestamp
  }
}

/**
 * Convert the time to the specified time format
 * @param timestamp
 * @param template
 */
export function formatDateTime(
  timestamp: string | number | Dayjs,
  template = TIME_FORMAT.fullDateTime
): string {
  if (!timestamp) {
    return ''
  }
  timestamp = safariTimestampFormat(timestamp)
  return dayjs(timestamp).format(template)
}

/**
 * load script
 * @param src
 * @param id
 */
export function loadScript(src: string, id: string): Promise<any> {
  const script = 'script'
  const firstScript: HTMLScriptElement =
    document.getElementsByTagName(script)[0]
  if (document.getElementById(id)) {
    return Promise.resolve()
  }
  const scriptElement: HTMLScriptElement = document.createElement(script)
  scriptElement.id = id
  scriptElement.src = src
  firstScript.parentNode?.insertBefore(scriptElement, firstScript)

  return new Promise((resolve, reject) => {
    scriptElement.onload = resolve
    scriptElement.onerror = reject
  })
}

/**
 * get all user agent
 */
export function getAllUserAgent() {
  const ua: string = window.navigator.userAgent.toLowerCase()

  const wechat = ua.includes('micromessenger')
  const android = ua.includes('android')
  const ios = ua.includes('iphone')
  const ipados = ua.includes('ipad')

  return {
    // app user agent
    wechat,
    weibo: ua.includes('weibo'),
    abcwallet: ua.includes('abcwallet'),
    mathwallet: ua.includes('mdsapp'),
    tokenpocket: ua.includes('tokenpocket'),
    bitpie: ua.includes('bitpie'),
    // platform user agent
    android,
    ios,
    ipados,
    mobile: android || ios,
    windows: ua.includes('windows'),
    ubuntu: ua.includes('ubuntu'),
    mac: ua.includes('mac'),
    messenger:
      !wechat &&
      (ua.includes('messenger') ||
        ua.includes('fbav') ||
        ua.includes('fban') ||
        window.location.href.includes('fb_iframe_origin'))
  }
}

/**
 * detects if the given user agent is supported
 * @param uaList
 */
export function isUASupported(uaList: string[]): boolean {
  const uaMap = getAllUserAgent()
  return uaList.some((ua) => (uaMap as any)[ua])
}

/**
 * check if the current access environment is a SafePal wallet environment
 */
export function isSafePalWallet(): boolean {
  if (!window) return false
  //@ts-ignore
  const { ethereum } = window
  return !!ethereum?.isSafePal
}

/**
 * check if the current access environment is a Bitpie wallet environment
 */
export function isBitpieWallet(): boolean {
  return isUASupported(['bitpie'])
}

/**
 * check if the current access environment is a MathWallet environment
 */
export function isMathWallet(): boolean {
  return isUASupported(['mathwallet'])
}

/**
 * check if the current access environment is a TokenPocket environment
 */
export function isTokenPocket(): boolean {
  return isUASupported(['tokenpocket'])
}

/**
 * check if the current access environment is a Coinbase Wallet environment
 */
export function isCoinbaseWallet(): boolean {
  const { ethereum } = window as any
  if (typeof ethereum !== 'undefined') {
    return !!ethereum.isCoinbaseWallet
  } else {
    return false
  }
}

/**
 * check if the current access environment is a ViaWallet wallet environment
 */
export function isViaWallet(): boolean {
  const { ethereum } = window as any
  if (typeof ethereum !== 'undefined') {
    return !!ethereum.isViaWallet
  } else {
    return false
  }
}

/**
 * string initial capitalization
 * @param value
 */
export function capitalize(value: string): string {
  if (value) {
    return value.replace(/^\S/, (s) => s.toUpperCase())
  } else {
    return ''
  }
}

/**
 * sleep for a fixed time
 * @param ms
 */
export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Find the corresponding key-value pair by parsing the value of the record
 * @param value
 */
export function findParsingRecordChain(value: string): ICoinTypeInfo {
  const chain = PARSING_RECORD_SUPPORT_CHAINS.find((item) => {
    return item.value === value || item.coinType === value
  })
  if (chain) {
    return chain
  } else {
    return {
      text: value.toUpperCase(),
      value,
      coinType: ''
    }
  }
}

/**
 * Find the corresponding key-value pair by profile value
 * @param value
 */
export function findProfile(value: string): { text: string; value: string } {
  const profile = PROFILE_KEY_OPTIONS.find((item) => {
    return item.value === value
  })
  if (profile) {
    return profile
  } else {
    return {
      text: value.toUpperCase(),
      value
    }
  }
}

/**
 * Find the corresponding key-value pair by Dweb value
 * @param value
 */
export function findDweb(value: string): { text: string; value: string } {
  const dweb = DWEB_KEY_OPTIONS.find((item) => {
    return item.value === value
  })
  if (dweb) {
    return dweb
  } else {
    return {
      text: value?.toUpperCase(),
      value
    }
  }
}

/**
 * The visual length of the string
 * @param str
 */
export const stringVisualLength = (str: string): number => {
  const splitter = new GraphemeSplitter()
  const split = splitter.splitGraphemes(str)
  return split.length
}

/*
 *  Convert signature data from tp-utxo to hex
 *  @param {string} base64SignData base64 encoded signature data
 *  @return {string} hex encoded signature data
 */
export function convertUTXOSignature(base64SignData: string): string {
  const buffer = Buffer.from(base64SignData, 'base64')
  if (buffer.length !== 65) throw new Error('Invalid signature length')
  const flagByte = buffer.readUInt8(0) - 27
  if (flagByte > 15 || flagByte < 0) {
    throw new Error('Invalid signature parameter')
  }

  const decodeData = {
    compressed: !!(flagByte & 12),
    segwitType: !(flagByte & 8)
      ? null
      : !(flagByte & 4)
        ? 'p2sh(p2wpkh)'
        : 'p2wpkh',
    recovery: flagByte & 3,
    signature: buffer.slice(1)
  }
  const signData = Buffer.concat([
    decodeData.signature,
    Buffer.from([decodeData.recovery]),
    Buffer.from([decodeData.compressed ? 1 : 0])
  ])
  return '0x' + signData.toString('hex')
}

export function isUTXOChain(coinType: CoinType | string) {
  return [CoinType.doge, CoinType.btc].includes(coinType as CoinType)
}

export function addressValidate(
  value: string,
  symbol: string,
  isProd: boolean = true
): boolean {
  try {
    const networkType = isProd ? 'prod' : 'testnet'
    symbol = symbol.toUpperCase()
    if (symbol === CKB.symbol) {
      if (isProd) {
        return /^ckb1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{42,}$/i.test(value)
      } else {
        return /^ckt1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{42,}$/i.test(value)
      }
    }

    if (
      [BSC.symbol, 'BSC', 'HECO', Polygon.symbol, 'POLYGON'].includes(symbol)
    ) {
      symbol = ETH.symbol
    }

    symbol = symbol.toLowerCase()

    if (symbol === ETH.symbol) {
      return (
        /^0x[0-9a-f]{40}$/i.test(value) && validate(value, symbol, networkType)
      )
    }

    try {
      return validate(value, symbol, networkType)
    } catch (err) {
      return true
    }
  } catch (err: any) {
    ErrorInfo.error(err)
    return false
  }
}

export function stringMaxLengthValidate(
  value: string,
  length: number
): boolean {
  return stringVisualLength(value) <= length
}

export function positiveIntegersValidate(value: string): boolean {
  return parseInt(value) === Number(value) && Number(value) > 0
}

export function profileValueValidate(value: string, key: string): boolean {
  switch (key) {
    case ParsingRecordProfileKey.twitter:
      return /^[a-zA-Z0-9_]+$/g.test(value) || TwitterUserUrlRegExp.test(value)
    case ParsingRecordProfileKey.nextid:
      return /^0x[A-Fa-f0-9]*:(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(
        value
      )
    default:
      return true
  }
}

export function objectKeyValidate(value: string): boolean {
  return /^[a-z0-9_]+$/g.test(value)
}

/**
 * Determine if the domain host is correct
 * @param host
 */
export function isDomainHost(host: string): boolean {
  try {
    return isValidDomain(host, {
      subdomain: true,
      wildcard: false,
      allowUnicode: true,
      topLevel: false
    })
  } catch (err) {
    console.error(err)
    return false
  }
}

export function dwebValueValidate(value: string, key: string): boolean {
  switch (key) {
    case ParsingRecordDwebKey.ipfs:
      try {
        const cid = new CID(value.replace(/ipfs:\/\//i, ''))
        if (
          (cid.codec === 'dag-pb' &&
            cid.multibaseName === 'base58btc' &&
            cid.version === 0) ||
          (cid.codec === 'dag-pb' &&
            cid.multibaseName === 'base32' &&
            cid.version === 1)
        ) {
          return true
        } else {
          return false
        }
      } catch (err) {
        console.log(err)
        return false
      }
    case ParsingRecordDwebKey.ipns:
      if (isDomainHost(value)) {
        return true
      }
      try {
        const cid = new CID(value.replace(/ipns:\/\//i, ''))
        if (
          (cid.codec === 'libp2p-key' &&
            cid.multibaseName === 'base36' &&
            cid.version === 1) ||
          (cid.codec === 'libp2p-key' &&
            cid.multibaseName === 'base32' &&
            cid.version === 1)
        ) {
          return true
        } else {
          return false
        }
      } catch (err) {
        console.log(err)
        return false
      }
    default:
      return true
  }
}

export function validateParams(
  params: { [key: string]: any },
  outerFuncName: string
) {
  if (typeof params !== 'object' || params === null) {
    throw new Error('validateParams params input is not an object')
  }
  Object.entries(params).forEach((param) => {
    if (!param[1]) {
      try {
        const _value: string = param[1]?.toString()
        throw new Error(
          `${outerFuncName} params value for property "${param[0]}" is ${_value}`
        )
      } catch (err: any) {
        throw new Error(
          `${outerFuncName} params value for property "${
            param[0]
          }", ${err.toString()}`
        )
      }
    }
  })
}

/**
 * Display the given name based on certain conditions.
 *
 * @param name - The input string name.
 * @param isTopLevelDid - A boolean flag to determine the format.
 * @returns - Formatted name.
 */
export function displayDid(name: string, isTopLevelDid = false): string {
  if (!name) {
    return ''
  }
  const segments = name.split('.')
  if (segments.length > 2) {
    segments.pop()
    return segments.join('.')
  }

  if (isTopLevelDid) {
    segments.pop()
    return '.' + segments.join('')
  }

  return name
}

export function removeDotbitSuffix(account: string): string {
  if (typeof account === 'string') {
    const pattern = /^(([^\\.\s]+\.){1,})bit$/
    const result = account.match(pattern)
    if (result) {
      return result[1].slice(0, -1)
    }
  }
  return ''
}

/**
 * get the symbol of the action
 * @param action
 */
export const getActionSymbol = (action: string): string => {
  const outputActions: string[] = [
    DIDPOINT_ACTIONS.burn,
    DIDPOINT_ACTIONS.transfer,
    DIDPOINT_ACTIONS.transfer_tldid,
    DIDPOINT_ACTIONS.transfer_sldid,
    DIDPOINT_ACTIONS.transfer_auction,
    DIDPOINT_ACTIONS.transfer_coupon
  ]
  const inputActions: string[] = [
    DIDPOINT_ACTIONS.mint,
    DIDPOINT_ACTIONS.transfer_deposit,
    DIDPOINT_ACTIONS.transfer_refund
  ]
  if (outputActions.includes(action)) {
    return '-'
  } else if (inputActions.includes(action)) {
    return '+'
  }
  return ''
}

/**
 * Converts to a checksum address
 *
 * @method toChecksumAddress
 * @param {String} address the given HEX address
 * @return {String}
 */
export function toChecksumAddress(address: string): string {
  if (typeof address === 'undefined') return ''

  if (!/^(0x)?[0-9a-f]{40}$/i.test(address))
    // eslint-disable-next-line lingui/no-unlocalized-strings
    throw new Error(
      `Given address "${address}" is not a valid Ethereum address.`
    )

  address = address.toLowerCase().replace(/^0x/i, '')
  const hash = new Keccak(256).update(address).digest('hex')
  const addressHash = hash.replace(/^0x/i, '')
  let checksumAddress = '0x'

  for (let i = 0; i < address.length; i++) {
    // If ith character is 8 to f then make it uppercase
    if (parseInt(addressHash[i], 16) > 7) {
      checksumAddress += address[i].toUpperCase()
    } else {
      checksumAddress += address[i]
    }
  }
  return checksumAddress
}

export const debounce = lodashDebounce

export const merge = lodashMerge

export { Decimal }

export { confetti }

export const generateAvatarRandomNumber = (
  inputString: string,
  total: number
) => {
  const hashValue = new Keccak(256).update(inputString).digest('hex')
  const hashInt = parseInt(hashValue || '', 16)
  return hashInt % (total + 1)
}

/**
 * Convert the string to lowercase
 * @param value
 */
export function toLowerCase(value: string): string {
  if (value && typeof value === 'string') {
    return value.toLowerCase()
  }
  return value
}

/**
 * Convert the string to uppercase
 * @param value
 */
export function toUpperCase(value: string): string {
  if (value && typeof value === 'string') {
    return value.toUpperCase()
  }
  return value
}
