import GraphemeSplitter from "grapheme-splitter";

import emojiList from "./char-set/emoji_list.json";
// @ts-ignore
import numberList from "./char-set/digit_list.json";
// @ts-ignore
import englishList from "./char-set/en_list.json";
// @ts-ignore
import turkishList from "./char-set/tr_list.json";
// @ts-ignore
import thaiList from "./char-set/th_list.json";
// @ts-ignore
import koreanList from "./char-set/ko_list.json";
// @ts-ignore
import vietnameseList from "./char-set/vi_list.json";
import { ACCOUNT_SUFFIX } from "@did/constants";

export enum DigitalEmojiMap {
  "0⃣️" = "0️⃣",
  "1⃣️" = "1️⃣",
  "2⃣️" = "2️⃣",
  "3⃣️" = "3️⃣",
  "4⃣️" = "4️⃣",
  "5⃣️" = "5️⃣",
  "6⃣️" = "6️⃣",
  "7⃣️" = "7️⃣",
  "8⃣️" = "8️⃣",
  "9⃣️" = "9️⃣",
  "0⃣" = "0️⃣",
  "1⃣" = "1️⃣",
  "2⃣" = "2️⃣",
  "3⃣" = "3️⃣",
  "4⃣" = "4️⃣",
  "5⃣" = "5️⃣",
  "6⃣" = "6️⃣",
  "7⃣" = "7️⃣",
  "8⃣" = "8️⃣",
  "9⃣" = "9️⃣",
}

export enum CHAR_TYPE {
  emoji = 0,
  number = 1,
  english = 2,
  simplifiedChinese = 3,
  traditionalChinese,
  japanese,
  korean,
  russian,
  turkish,
  thai,
  vietnamese,
  unknown = 99,
}

export interface ICharInfo {
  char_set_name: number;
  char: string;
}

export function digitalEmojiHandle(account: string): string {
  const splitter = new GraphemeSplitter();
  const split = splitter.splitGraphemes(account);
  const list = split.map((item) => {
    // @ts-ignore
    if (DigitalEmojiMap[item]) {
      // @ts-ignore
      return DigitalEmojiMap[item];
    } else {
      return item;
    }
  });
  return list.join("");
}

export function splitAccount(account: string, addSuffix = false): ICharInfo[] {
  const splitter = new GraphemeSplitter();
  let split = splitter.splitGraphemes(account);

  split = split.map((item) => {
    // @ts-ignore
    if (DigitalEmojiMap[item]) {
      // @ts-ignore
      return DigitalEmojiMap[item];
    } else {
      return item;
    }
  });

  const englishSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown;
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji;
    } else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number;
    } else if (englishList.includes(char)) {
      _charType = CHAR_TYPE.english;
    }

    return {
      char_set_name: _charType,
      char,
    };
  });

  const turkishSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown;
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji;
    } else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number;
    } else if (turkishList.includes(char)) {
      _charType = CHAR_TYPE.turkish;
    }

    return {
      char_set_name: _charType,
      char,
    };
  });

  const vietnameseSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown;
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji;
    } else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number;
    } else if (vietnameseList.includes(char)) {
      _charType = CHAR_TYPE.vietnamese;
    }

    return {
      char_set_name: _charType,
      char,
    };
  });

  const thaiSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown;
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji;
    } else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number;
    } else if (thaiList.includes(char)) {
      _charType = CHAR_TYPE.thai;
    }

    return {
      char_set_name: _charType,
      char,
    };
  });

  const koreanSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown;
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji;
    } else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number;
    } else if (koreanList.includes(char)) {
      _charType = CHAR_TYPE.korean;
    }

    return {
      char_set_name: _charType,
      char,
    };
  });

  const englishUnknownChar = englishSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown;
  });
  const turkishUnknownChar = turkishSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown;
  });
  const vietnameseUnknownChar = vietnameseSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown;
  });
  const thaiUnknownChar = thaiSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown;
  });
  const koreanUnknownChar = koreanSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown;
  });

  let splitArr = null;

  if (!englishUnknownChar) {
    splitArr = englishSplitArr;
  } else if (!turkishUnknownChar) {
    splitArr = turkishSplitArr;
  } else if (!vietnameseUnknownChar) {
    splitArr = vietnameseSplitArr;
  } else if (!thaiUnknownChar) {
    splitArr = thaiSplitArr;
  } else if (!koreanUnknownChar) {
    splitArr = koreanSplitArr;
  } else {
    splitArr = split.map((char: string) => {
      return {
        char_set_name: CHAR_TYPE.unknown,
        char,
      };
    });
  }

  const unknownChar = splitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown;
  });

  if (!unknownChar) {
    const charList: { [key: string]: any } = {};
    if (!englishUnknownChar) {
      charList.en = englishSplitArr;
    }

    if (!turkishUnknownChar) {
      charList.tr = turkishSplitArr;
    }

    if (!vietnameseUnknownChar) {
      charList.vi = vietnameseSplitArr;
    }

    if (!thaiUnknownChar) {
      charList.th = thaiSplitArr;
    }

    if (!koreanUnknownChar) {
      charList.ko = koreanSplitArr;
    }

    let language = window.navigator.language;
    if (/^en/i.test(language)) {
      language = "en";
    } else if (/^ja/i.test(language)) {
      language = "ja";
    } else if (/^ru/i.test(language)) {
      language = "ru";
    } else if (/^tr/i.test(language)) {
      language = "tr";
    } else if (/^vi/i.test(language)) {
      language = "vi";
    } else if (/^th/i.test(language)) {
      language = "th";
    } else if (/^ko/i.test(language)) {
      language = "ko";
    }

    if (charList[language]) {
      splitArr = charList[language];
    } else if (charList.en) {
      splitArr = charList.en;
    }
  }

  if (addSuffix) {
    ACCOUNT_SUFFIX.split("").forEach((char: string) => {
      splitArr.push({
        char_set_name: CHAR_TYPE.english,
        char,
      });
    });
  }

  return splitArr;
}

export function accountChars(account: string): ICharInfo[] {
  const splitter = new GraphemeSplitter();
  let split = splitter.splitGraphemes(account);

  split = split.map((item) => {
    // @ts-ignore
    if (DigitalEmojiMap[item]) {
      // @ts-ignore
      return DigitalEmojiMap[item];
    } else {
      return item;
    }
  });

  const chars: ICharInfo[] = split.map((char) => {
    let _charType: number = CHAR_TYPE.unknown;
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji;
    } else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number;
    } else if (englishList.includes(char)) {
      _charType = CHAR_TYPE.english;
    } else if (turkishList.includes(char)) {
      _charType = CHAR_TYPE.turkish;
    } else if (vietnameseList.includes(char)) {
      _charType = CHAR_TYPE.vietnamese;
    } else if (thaiList.includes(char)) {
      _charType = CHAR_TYPE.thai;
    } else if (koreanList.includes(char)) {
      _charType = CHAR_TYPE.korean;
    }

    return {
      char_set_name: _charType,
      char,
    };
  });

  return chars;
}
