import { ConnectWalletType } from '@did/types/uikit'
import { collapseString } from '@did/tools'
import { useMemo } from 'react'

export const ConnectWallet: ConnectWalletType = ({
  slots,
  chainIconName,
  address,
  empty,
  onClick,
  className,
  bitName
}) => {
  const collapseAddress = useMemo(() => {
    if (!address) return ''

    if (address.length < 30) return address

    return collapseString(address, 4, 4)
  }, [address])

  return (
    <slots.button
      className={className}
      slots={{ icon: slots.icon }}
      onClick={onClick}
    >
      {address ? (
        <>
          <slots.icon name={chainIconName} size="20" className="mr-2" />
          {!bitName && (
            <span className="text-xs font-semibold text-neutral-700 leading-none text-left pr-2 break-all">
              {collapseAddress}
            </span>
          )}
          {bitName && (
            <div className="text-left pr-2">
              <div className="text-xs font-semibold leading-none text-neutral-700 mb-1">
                {bitName.length > 10 ? collapseString(bitName) : bitName}
              </div>
              <div className="text-xs text-gray-400 font-normal leading-none whitespace-nowrap">
                {collapseAddress}
              </div>
            </div>
          )}
        </>
      ) : (
        <span className="text-base font-semibold text-neutral-700 px-1 leading-none">
          {empty}
        </span>
      )}
    </slots.button>
  )
}
