import { TextInputType } from '@did/types/uikit'
import React, { useCallback } from 'react'
import { cn } from '@did/tools'
import { useCompositionInput } from 'foxact/use-composition-input'

export const TextInput: TextInputType = ({
  onChange,
  value,
  placeholder,
  suffix,
  className,
  onBlur,
  errorMessages,
  disabled
}) => {
  return (
    <div className={cn('relative', className)}>
      <input
        onBlur={onBlur}
        defaultValue={value}
        {...useCompositionInput(
          useCallback(
            (value: string) => {
              onChange?.(value)
            },
            [onChange]
          )
        )}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(
          'w-full h-14 px-6 py-3 rounded-xl bg-gray-100 border outline-0 focus:bg-white disabled:cursor-no-drop',
          {
            ['pr-4']: Boolean(suffix),
            ['border-white hover:border-primary focus:border-primary']:
              !Boolean(errorMessages?.[0]),
            ['border-red-500 bg-white focus:border-red-500 hover:border-red-500 ']:
              Boolean(errorMessages?.[0])
          }
        )}
      />
      {suffix && (
        <div className="absolute right-6 top-0 h-14 flex items-center font-semibold text-sm">
          {suffix}
        </div>
      )}
      {errorMessages?.[0] && (
        <div className=" mt-1 text-red-500 text-xs font-medium">
          {errorMessages[0]}
        </div>
      )}
    </div>
  )
}
