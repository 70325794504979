import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { DidCardType } from '@did/types/uikit'
import BgCardPNG from '@did/das-assets/images/bg-card.png'
import { cn, debounce, isMobile } from '@did/tools'
import { ACCOUNT_SUFFIX } from '@did/constants'
import { digitalEmojiHandle } from '@did/tools/bit-account-string-handle'
import GraphemeSplitter from 'grapheme-splitter'
import emojiRegex from 'emoji-regex'

export const DidCard: DidCardType = ({
  account,
  className,
  width,
  onLoaded
}) => {
  const itemRef = useRef<any>(null)
  const parentRef = useRef<any>(null)
  const [isAnimated, setIsAmnimated] = useState(false)
  const [parentStyle, setParentStyle] = useState<any>({})
  const [itemStyle, setItemStyle] = useState<any>({})
  const [glareStyle, setGlareStyle] = useState<any>({})
  const [shadowStyle, setShadowStyle] = useState({
    transform: 'translateX(0px) translateY(10px)',
    backgroundColor: 'rgba(103, 97, 105, 0.25)'
  })
  const [accountFontSize, setAccountFontSize] = useState(0)
  const [parentWidth, setParentWidth] = useState(0)
  let [isEnter, setIsEnter] = useState(false)
  let [, setIsLeave] = useState(true)
  let [isAnimating, setIsAnimating] = useState(false)
  const [isLoadedImg, setIsLoadedImg] = useState(false)

  const itemPosition = useMemo(
    () => itemRef.current?.getBoundingClientRect() || {},
    [itemRef.current]
  )

  const calculateAngle = useCallback(
    (e: any) => {
      setIsAmnimated(true)

      const clientX = e.touches ? e.touches[0].clientX : e.clientX
      const clientY = e.touches ? e.touches[0].clientY : e.clientY

      const x =
        itemPosition.x > clientX
          ? -(itemPosition.x - clientX)
          : Math.abs(itemPosition.x - clientX)
      const y =
        itemPosition.y > clientY
          ? -(itemPosition.y - clientY)
          : Math.abs(itemPosition.y - clientY)

      let halfWidth = itemPosition.width / 2
      let halfHeight = itemPosition.height / 2

      let calcAngleX = (x - halfWidth) / 6
      if (calcAngleX > 45) {
        calcAngleX = 45
      } else if (calcAngleX < -45) {
        calcAngleX = -45
      }
      let calcAngleY = (y - halfHeight) / 14
      if (calcAngleY > 45) {
        calcAngleY = 45
      } else if (calcAngleY < -45) {
        calcAngleY = -45
      }

      let calcShadowX = (x - halfWidth) / 3
      if (calcShadowX > 45) {
        calcShadowX = 45
      } else if (calcShadowX < -45) {
        calcShadowX = -45
      }
      let calcShadowY = (y - halfHeight) / 6
      if (calcShadowY > 45) {
        calcShadowY = 45
      } else if (calcShadowY < -45) {
        calcShadowY = -45
      }

      let gX = (1 - x / (halfWidth * 2)) * 100
      let gY = (1 - y / (halfHeight * 2)) * 100

      setGlareStyle({
        background: `radial-gradient(circle at ${gX}% ${gY}%, rgb(199 198 243), transparent)`
      })
      setParentStyle({
        perspective: `${halfWidth * 6}px`
      })
      setItemStyle({
        perspective: `${halfWidth * 6}px`,
        transform: `rotateY(${calcAngleX}deg) rotateX(${-calcAngleY}deg) scale(1.04)`
      })
      setShadowStyle({
        transform: `translateX(${-calcShadowX}px) translateY(${-calcShadowY}px)`,
        backgroundColor: 'rgba(103, 97, 105, 0.25)'
      })
    },
    [itemPosition]
  )

  const handleMouseMove = (e: any) => {
    if (isAnimating) return
    setIsAmnimated(true)
    setIsEnter(true)
    setIsLeave(false)
    setIsAnimating(true)
    requestAnimationFrame(() => {
      calculateAngle(e)
      setIsAnimating(false)
    })
    e.stopPropagation()
  }

  const handleMouseLeave = useCallback(
    debounce(() => {
      if (!isEnter) return
      setItemStyle({
        transform: `rotateY(0deg) rotateX(0deg) scale(1)`
      })
      setShadowStyle({
        transform: `translateX(0px) translateY(10px)`,
        backgroundColor: 'rgba(103, 97, 105, 0.25)'
      })
      setIsAmnimated(false)
      setIsEnter(false)
      setIsLeave(true)
    }, 200),
    [isEnter]
  )

  const cardWidth = useMemo(() => `${parentWidth}px`, [parentWidth])
  const cardHeight = useMemo(
    () => parseInt(`${parentWidth * 1.327}`) + 'px',
    [parentWidth]
  )

  const onImgLoaded = useCallback(() => {
    setIsLoadedImg(true)
    onLoaded?.()
  }, [onLoaded])

  const onMobileTouchStart = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const nameToSize = (
    name: string
  ): {
    isMultiline: boolean
    fontSize: number
  } => {
    let length = 0
    for (const char of new GraphemeSplitter().splitGraphemes(name)) {
      if (emojiRegex().test(char)) {
        length += 2.5
      } else if (char === 'w' || char === 'm') {
        length += 1.4
      } else if (/[0-9a-z]/.test(char)) {
        length += 1
      } else {
        length += 2
      }
    }
    let res = {
      isMultiline: true,
      fontSize: 3.6
    }
    if (length <= 6) {
      res.fontSize = 20
      res.isMultiline = false
    } else if (length <= 10) {
      res.fontSize = 18
    } else if (length <= 50) {
      res.fontSize = Math.floor(18000 / length) / 100
    }
    return res
  }

  const handleName = (name: string) => {
    if (!name) {
      return ''
    }
    let _name = digitalEmojiHandle(name)
      .replace(/\s+/g, '')
      .toLowerCase()
      .replace(/\.bit$/, '')

    const { isMultiline } = nameToSize(_name)

    _name = _name + ACCOUNT_SUFFIX

    return _name?.replace(/\.bit$/, '').split('.').length > 1 ? (
      isMultiline ? (
        <>
          {_name?.replace(/\.bit$/, '').split('.')[0]}
          <br />.{_name?.replace(/\.bit$/, '').split('.')[1]}
        </>
      ) : (
        _name?.replace(/\.bit$/, '')
      )
    ) : isMultiline ? (
      <>
        {_name?.replace(/\.bit$/, '')}
        <br />
        {ACCOUNT_SUFFIX}
      </>
    ) : (
      _name
    )
  }

  useEffect(() => {
    if (width) {
      setParentWidth(
        /^\d+%$/.test(width)
          ? (parentRef.current?.offsetWidth *
              parseInt(width.replace('%', ''))) /
              100
          : parseInt(width)
      )
    } else {
      setParentWidth(parentRef.current?.offsetWidth)
    }
  }, [width])

  useEffect(() => {
    if (!account) return

    let _name = digitalEmojiHandle(account)
      .replace(/\s+/g, '')
      .toLowerCase()
      .replace(/\.bit$/, '')

    const { fontSize } = nameToSize(_name)
    setAccountFontSize(fontSize)
  }, [account])

  useEffect(() => {
    parentRef.current?.addEventListener('touchstart', onMobileTouchStart, {
      passive: false
    })
  }, [])

  return (
    <div
      className={cn(
        'card p-0 m-0 transition-all relative ease-out duration-200 border-0 tracking-wide flex justify-center items-center z-1',
        className
      )}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchMove={handleMouseMove}
      onTouchEnd={handleMouseLeave}
      ref={parentRef}
      style={parentWidth ? parentStyle : {}}
    >
      {parentWidth ? (
        <>
          <div
            className="absolute blur-[20px] w-[100%] h-[100%] will-change-auto"
            style={
              !isMobile ? { ...shadowStyle, opacity: isLoadedImg ? 1 : 0 } : {}
            }
          ></div>
          <div
            className="inner-card text-5xl text-white leading-12 relative float-none bg-center block m-auto rotate-0 scale-100 top-0 box-border transition-all ease-out duration-150 font-semibold tracking-normal will-change-auto"
            ref={itemRef}
            style={{
              ...itemStyle,
              width: cardWidth,
              height: cardHeight,
              opacity: isLoadedImg ? 1 : 0
            }}
          >
            <div
              className="absolute top-0 left-0 z-0 will-change-auto"
              style={{ width: cardWidth, height: cardHeight }}
            >
              <img
                className="drop-shadow-did-card"
                src={BgCardPNG.src}
                onLoad={onImgLoaded}
                width="100%"
                height="100%"
                alt=""
              />
              <span
                className={cn(
                  'glare absolute top-0 left-0 w-full h-full transition-all ease-out duration-100 pointer-events-none z-50 will-change-auto rounded-e-lg',
                  isAnimated ? 'opacity-30' : 'opacity-0'
                )}
                style={glareStyle}
              ></span>
            </div>
            <div className="relative z-1 p-2 w-full h-full">
              <div className="absolute h-[36%] left-0 mx-auto px-1 right-0 top-[47%]">
                <div
                  className="text-amber-100 font-semibold text-center break-keep leading-[110%] h-[1000%] w-[1000%] scale-[0.1] origin-[0_0]"
                  style={{
                    fontSize: `${accountFontSize * 10}px`
                  }}
                >
                  {accountFontSize ? handleName(account) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}
