import {
  BaseError,
  ErrorLevelEnum,
  ErrorType,
  ErrorLevel,
  ErrorTypeEnum,
  IError
} from './error'
import * as SentryModule from '@sentry/nextjs'
import errorMap from '@did/constants/errno'

export type InfoType = IError | string

export class ErrorInfo {
  wrapperError(error: InfoType) {
    const errorObj = {
      code: errorMap.unknownError,
      message: 'unknown error',
      type: ErrorTypeEnum.CODE
    }
    if (typeof error === 'object') {
      if (error.code != undefined) {
        errorObj.code = error.code
      }
      if (error.type != undefined) {
        errorObj.type = error.type
      }
      errorObj.message = error.message
    }
    if (typeof error === 'string') {
      errorObj.message = error
    }
    return errorObj
  }

  captureError(
    error: BaseError,
    extra?: Record<string, any>,
    tags?: Record<string, any>
  ) {
    try {
      SentryModule.captureException(error, {
        level: ErrorLevel[error.level] as SentryModule.SeverityLevel,
        extra,
        tags: {
          ...tags,
          type: ErrorType[error.type]
        }
      })
    } catch (e) {
      console.log('========== Error ===========')
      console.log(e)
    }
  }

  captureMessage(
    error: BaseError,
    extra?: Record<string, any>,
    tags?: Record<string, any>
  ) {
    try {
      SentryModule.captureException(error, {
        level: ErrorLevel[error.level] as SentryModule.SeverityLevel,
        extra,
        tags: {
          ...tags,
          type: ErrorType[error.type]
        }
      })
    } catch (e) {
      console.log('========== Error ===========')
      console.log(e)
    }
  }

  log(...args: any[]) {
    return console.log(args)
  }

  debug(error: InfoType) {
    const errorObj = this.wrapperError(error)
    const baseError = new BaseError(
      errorObj.code,
      errorObj.message,
      ErrorLevelEnum.DEBUG,
      errorObj.type
    )
    this.captureMessage(baseError)
    console.log('========== Error ===========')
    console.log(baseError)
  }

  info(error: InfoType) {
    const errorObj = this.wrapperError(error)
    const baseError = new BaseError(
      errorObj.code,
      errorObj.message,
      ErrorLevelEnum.INFO,
      errorObj.type
    )
    this.captureMessage(baseError)
    console.log('========== Error ===========')
    console.log(baseError)
  }

  warning(error: InfoType) {
    const errorObj = this.wrapperError(error)
    const baseError = new BaseError(
      errorObj.code,
      errorObj.message,
      ErrorLevelEnum.WARNING,
      errorObj.type
    )
    this.captureMessage(baseError)
    console.log('========== Error ===========')
    console.log(baseError)
  }

  error(error: InfoType) {
    const errorObj = this.wrapperError(error)
    const baseError = new BaseError(
      errorObj.code,
      errorObj.message,
      ErrorLevelEnum.ERROR,
      errorObj.type
    )
    this.captureError(baseError)
    console.log('========== Error ===========')
    console.log(baseError)
  }
}

export const errorInfo = new ErrorInfo()
