import { TypedMessage, MessageTypes } from '@metamask/eth-sig-util'
import { IDidPointService } from './did-point'
import { ISubAccountService } from './sub-account'

export * from './did-point'
export * from './sub-account'

export enum SIGN_TYPE {
  noSign,
  ckbMulti,
  ckbSingle,
  eth,
  tron,
  eth712,
  ed25519,
  doge,
  webauthn,
  btc
}

export interface SignInfo {
  sign_type: SIGN_TYPE
  sign_msg: string
}

export enum ParsingRecordType {
  address = 'address',
  profile = 'profile',
  dweb = 'dweb',
  customKey = 'custom_key',
  owner = 'owner',
  manager = 'manager'
}

export enum ParsingRecordProfileKey {
  nostr = 'nostr',
  twitter = 'twitter',
  facebook = 'facebook',
  reddit = 'reddit',
  linkedin = 'linkedin',
  github = 'github',
  telegram = 'telegram',
  description = 'description',
  avatar = 'avatar',
  instagram = 'instagram',
  weibo = 'weibo',
  discord = 'discord',
  website = 'website',
  youtube = 'youtube',
  bilibili = 'bilibili',
  tiktok = 'tiktok',
  jike = 'jike',
  nextid = 'nextid',
  dribbble = 'dribbble',
  behance = 'behance',
  mirror = 'mirror',
  medium = 'medium'
}

export enum ParsingRecordDwebKey {
  ipfs = 'ipfs',
  ipns = 'ipns',
  arweave = 'arweave',
  resilio = 'resilio',
  skynet = 'skynet'
}

export enum DATA_ACTIONS {
  delete = 'delete',
  change = 'change',
  add = 'add'
}

export interface IReverseInfoParams {
  coinType: string
  address: string
}

export interface IKeyInfo {
  coin_type: string
  key: string
}

export enum TRX_STATUS {
  failed = -1,
  pending,
  success,
  unpackaged,
  unknown
}

export enum REVERSE_TX_STATUS {
  success = 0,
  pending,
  failed
}

export enum ORDER_ACTION_TYPE {
  APPLY_REGISTER = 'apply_register',
  WITHDRAW_FROM_WALLET = 0,
  SET_DAS_REVERSE = 8,
  EDIT_DAS_REVERSE = 9,
  TRANSFER_BALANCE = 7,
  DELETE_DAS_REVERSE = 10,
  EDIT_RECORDS = 12,
  TRANSFER_ACCOUNT = 13,
  EDIT_MANAGER = 14,
  RENEW_ACCOUNT = 15
}

export enum ACCOUNT_STATUS {
  error = -2,
  notOpenRegister = -1,
  registerable,
  registeringPaymentConfirm,
  registeringLockedAccount,
  registering,
  registeringIncludeProposal,
  registeringConfirmProposal,
  registered,
  reservedAccount,
  onePriceSell,
  auctionSell,
  candidateAccount,
  expired,
  othersRegistering,
  unavailableAccount,
  subAccountNotCreated,
  onCross,
  onDutchAuction = 17,
  auctionRecycling = 18,
  inTransferApproving = 19,
  upgradedAccount = 20
}

export interface IMainChain {
  name: string
  symbol: string
  coinType: string
  decimals: number
  icon: string
  tokenId: string
  getExplorerTrx: (isProd: boolean) => string
}

export interface ITrxHistoryParams {
  key_info: IKeyInfo
  page: number
  size?: number
}

export interface ITrxHistoryListInfo {
  account: string
  action: number
  block_number: number
  hash: string
  timestamp: number
  capacity: string
}

export interface ITrxHistoryRes {
  list: ITrxHistoryListInfo[]
  total: number
}

export interface IAccountParsingRecord {
  type: ParsingRecordType | string
  key: ParsingRecordProfileKey | ParsingRecordDwebKey | string
  label: string
  value: string
  ttl?: string
  action?: DATA_ACTIONS | string
}

export interface IAccountParsingRecords {
  records: IAccountParsingRecord[]
}

export interface IAvailableBalanceWithdrawParams {
  key_info: IKeyInfo
  evm_chain_id: number
  receiver_address: string
  amount: string
  withdraw_all: boolean
}

export interface SignTxListParams {
  action?: string
  sub_action?: string
  sign_key: string
  sign_list: SignInfo[]
  mm_json?: TypedMessage<MessageTypes>
  ckb_tx?: string
}

export interface SignTxListRes {
  action?: string
  sub_action?: string
  sign_key?: string
  sign_list?: SignInfo[]
  sign_address?: string
  ckb_tx?: string
}

export interface IEditOwnerRes extends SignTxListParams {
  order_id?: string
  receipt_address?: string
  amount?: string
  client_secret?: string
}

export interface ITransitBalanceTransferParams {
  key_info: IKeyInfo
  transfer_address: string
}

export interface IBalanceInfoParams {
  key_info: IKeyInfo
  transfer_address: string
}

export interface IBalanceInfoRes {
  transfer_address_amount: string
  das_lock_amount: string
}

export interface IToken {
  coin_type: string
  contract: string
  decimals: number
  name: string
  price: string
  symbol: string
  token_id: string
  logo: string
  premium: string
}

export interface IConfig {
  sale_cell_capacity: number // CKB that requires pledge for uploading account
  min_sell_price: number // minimum price for sale
  reverse_record_capacity: number
  min_change_capacity: number
  account_expiration_grace_period: number
  min_ttl: number
  profit_rate_of_inviter: string
  inviter_discount: string
  min_account_len: number
  max_account_len: number
  edit_records_throttle: number
  edit_manager_throttle: number
  transfer_throttle: number
  income_cell_min_transfer_value: number
  premium: string
  timestamp_on_chain: number
  premium_percentage: string // only stripe
  premium_base: string // only stripe
}

export interface IUpgradeNoticeRes {
  [key: string]: { [key: string]: string }
}

export interface IReverseInfoParams {
  coinType: string
  address: string
}

export enum REVERSE_INFO_STATUS {
  notSet = 0,
  setOldOnly,
  setBoth,
  setNewOnly
}

export interface IReverseInfoRes {
  account: string
  is_valid: boolean
  reverse_status?: REVERSE_INFO_STATUS
}

export interface ICheckReverseTxStatusParams extends IReverseInfoParams {}

export interface ICheckReverseTxStatusRes {
  status: REVERSE_TX_STATUS
  account: string
  action: 'update' | 'remove'
}

export interface ICheckAccountStatusParams extends IReverseInfoParams {
  account: string
}

export interface ICheckAccountStatusRes {}

export interface ISetDasReverseParams {
  key_info: IKeyInfo
  evm_chain_id: number
  account: string
}

export interface ISearchAccountsParams extends IReverseInfoParams {
  keyword: string
}

export interface ISearchAccountsRes {
  list: Array<{ account: string }>
}

export interface IUpdateReverseParams extends ICheckAccountStatusParams {
  action?: 'update' | 'remove'
}

export interface IUpdateReverseRes {
  sign_msg: string
  sign_type: SIGN_TYPE
  sign_key: string
}

export interface ISendSignatureParams {
  sign_key: string
  signature: string
  sign_address?: string
}

export interface ISendTxRes {
  hash: string
}

export interface ISendSignatureRes {}

export interface ICheckTxStatusParams {
  key_info: IKeyInfo
  actions: (string | number)[]
}

export interface ICheckTxStatusRes {
  block_number: number
  hash: string
  status: TRX_STATUS
}

export interface IRegisterTxMap {
  chain_id: number
  hash: string
  token_id: string
}
export interface ISearchAccountParams {
  key_info: IKeyInfo
  account: string
}

export interface ISearchAccountRes {
  status: ACCOUNT_STATUS
  account: string
  is_self: boolean
  account_price: string
  base_amount: string
  register_tx_map: { [key: string]: IRegisterTxMap }
  open_timestamp: string
}

export interface IAccountInfoRes {
  account: string
  owner: string
  manager: string
  registered_at: number
  expired_at: number
  status: ACCOUNT_STATUS
  account_price: string
  base_amount: string
  owner_coin_type: string
  manager_coin_type: string
  confirm_proposal_hash: string
  enable_sub_account?: boolean
  re_register_time?: string
}

export interface IMyDobsRes extends IAccountInfoRes {}

export interface IUpgradableListRes {
  account_id: string
  account: string
  expired_at: number
  upgrade_status: number
  upgrade_hash: string
}

export interface IRecyclableListRes {
  account: string
  expired_at: number
  recycle_status: number
  recycle_hash: string
}

export interface IUpgradePriceInfoRes {
  price_usd: string
  price_ckb: string
}

export interface ISubmitRegisterOrderParams {
  key_info: IKeyInfo
  account: string
  pay_token_id?: string
  pay_address?: string
  register_years: number
  coin_type: string
  inviter_account?: string
  channel_account: string
  gift_card?: string
}

export interface ISubmitRegisterOrderRes {
  order_id: string
  coin_type?: number
  receipt_address: string
  amount: string
  code_url: string
  token_id: string
  client_secret?: string
}

export interface IOrderDetailParams {
  account: string
  key_info: IKeyInfo
  action: ORDER_ACTION_TYPE
}

export interface IOrderDetailRes {
  order_id: string
  account: number
  action: string
  status: string
  timestamp: number
  pay_token_id: string
  pay_amount: string
  inviter_account: string
  register_years: number
  receipt_address: string
  code_url: string
  channel_account: string
  coin_type: string
  client_secret?: string
}

export interface IChangeOrderParams extends ISubmitRegisterOrderParams {}

export interface IChangeOrderRes extends ISubmitRegisterOrderRes {}

export enum COUPON_STATUS {
  'nonExistent' = 1,
  'used' = 2,
  'notStarted' = 3,
  'available' = 4
}

export enum COUPON_TYPE {
  'fourChars' = 1,
  'fiveCharsAndAbove' = 2
}
export interface ICouponCheckRes {
  type: COUPON_TYPE
  status: COUPON_STATUS
}

export interface IAccountInfoParams {
  key_info?: IKeyInfo
  category?: number
  keyword?: string
  page?: number
  size?: number
}

export type IMyDobsParams = Omit<IAccountInfoParams, 'category'>

export interface IUpgradableListParams extends IMyDobsParams {}

export interface IRecyclableListParams extends IMyDobsParams {}

export interface IUpgradePriceInfoParams {
  key_info?: IKeyInfo
  account: string
}

export interface IRegisteringAccountParams {
  key_info: IKeyInfo
}

export interface IDasBalancePayParams {
  key_info: IKeyInfo
  evm_chain_id: number
  order_id: string
}

export interface IReturnRegisteredPaymentTrxIdParams {
  key_info: IKeyInfo
  account: string
  order_id: string
  pay_hash: string
}

export interface IMyRewardsResInviteList {
  invitee: string
  reward: string
  invitation_time: string
}

export interface IMyRewardsRes {
  count: number
  list: IMyRewardsResInviteList[]
}

export interface IMyRewardsParams {
  key_info: IKeyInfo
  page: number
  size: number
}

export enum AUCTION_PARAM_TYPE {
  BLOCKCHAIN = 'blockchain'
}

export interface IAuctionInfoParams {
  type: string
  key_info: IKeyInfo
  account: string
}

export enum BidStatus {
  BidStatusNoOne = 0, // Account has no bidders
  BidStatusByOthers = 1, // Account is bid by others
  BidStatusByMe = 2 // Account is bid by me
}

export interface IAuctionInfoRes {
  account_id: string
  account: string
  bid_status: BidStatus
  start_auction_time: number
  expired_at: number
  base_amount: string
  account_price: string
  end_auction_time: number
  hash: string
}

export interface IAuctionPriceParam {
  account: string
}
export interface IAuctionPriceRes {
  base_amount: string
  account_price: string
  premium_price: number
}

export interface IAuctionOrderStatusParam {
  account: string
  type: string
  key_info: IKeyInfo
  hash: string
}

export enum AUCTION_STATUS {
  PENDING = 0,
  SUCCESS = 1,
  REJECTED = -1
}
export interface IAuctionOrderStatusRes {
  account: string
  order_id: string
  hash: string
  status: AUCTION_STATUS
  basic_price: string
  premium_price: string
}

export interface IAuctionPendingOrderParam {
  key_info: IKeyInfo
  type: string
}

export interface IAuctionBidParam {
  key_info: IKeyInfo
  account: string
  type: string
  coin_type?: string
}

export interface IAuctionBidRes {
  sign_msg: string
  sign_type: SIGN_TYPE
  sign_key: string
}

export interface IRecommendAccountParams {
  account: string
  page?: number
  size?: number
}

export interface IRecommendAccountRes {
  total_page?: number
  page?: number
  acc_list: string[]
}

export type IAuctionPendingOrderRes = IAuctionOrderStatusRes[]

export interface IEditRecordsParams {
  key_info: IKeyInfo
  account: string
  raw_param: {
    records: IAccountParsingRecord[]
  }
}

export interface IRecycleDobParams {
  key_info: IKeyInfo
  account: string
}

export interface ISubmitRenewOrderParams {
  key_info: IKeyInfo
  account: string
  pay_token_id: string
  renew_years: number
}

export interface ISubmitRenewOrderRes extends SignTxListParams {
  order_id: string
  receipt_address: string
  amount: string
  code_url: string
  token_id: string
  client_secret?: string
  ckb_tx?: any
}

export interface IEditOwnerParams {
  key_info: IKeyInfo
  account: string
  raw_param: {
    receiver_coin_type: string
    receiver_address: string
  }
  pay_token_id?: string
  is_upgrade?: boolean
}

export interface IEditManagerParams {
  key_info: IKeyInfo
  evm_chain_id: number
  account: string
  raw_param: {
    manager_coin_type: string
    manager_address: string
  }
}

export interface IReverseBatchItem {
  type: string
  key_info: IKeyInfo
}

export interface IReverseBatchParams {
  account: string
  key_info_list: IReverseBatchItem[]
}

export enum CKB_ADDRESS_TYPE {
  dasLock = 'das_lock',
  anyLock = 'any-lock'
}

export enum OPERATE_STATUS {
  not = 0,
  loading
}

export interface IReverseBatchResListItem {
  account: string
  reverse_status: REVERSE_INFO_STATUS
  coin_type: string
  is_valid: boolean
  key: string
  ckb_address_type: CKB_ADDRESS_TYPE
  operate_status: OPERATE_STATUS
}

export interface IReverseBatchRes {
  account: string
  list: IReverseBatchResListItem[]
}

export interface IAccountService {
  myTrxHistory(params: ITrxHistoryParams): Promise<ITrxHistoryRes>
  accountParsingRecords(accountName: string): Promise<IAccountParsingRecords>
  availableBalanceWithdraw(
    params: IAvailableBalanceWithdrawParams
  ): Promise<SignTxListParams>
  transitBalanceTransfer(
    params: ITransitBalanceTransferParams
  ): Promise<SignTxListParams>
  balanceInfo(params: IBalanceInfoParams): Promise<IBalanceInfoRes>
  checkTxStatus(params: ICheckTxStatusParams): Promise<ICheckTxStatusRes>
  accountInfo(accountName: string): Promise<IAccountInfoRes>
  searchAccount(param: ISearchAccountParams): Promise<ISearchAccountRes>
  couponCheck(coupon: string): Promise<ICouponCheckRes>
  submitRegisterOrder(
    params: ISubmitRegisterOrderParams
  ): Promise<ISubmitRegisterOrderRes>
  orderDetail(params: IOrderDetailParams): Promise<IOrderDetailRes>
  changeOrder(params: IChangeOrderParams): Promise<IChangeOrderRes>
  myAccounts(
    params: IAccountInfoParams
  ): Promise<{ list: IAccountInfoRes[]; total: number }>
  myDobs(params: IMyDobsParams): Promise<{ list: IMyDobsRes[]; total: number }>
  upgradableList(
    params: IUpgradableListParams
  ): Promise<{ list: IUpgradableListRes[]; total: number }>
  recyclableList(
    params: IRecyclableListParams
  ): Promise<{ list: IRecyclableListRes[]; total: number }>
  recycleDob(params: IRecycleDobParams): Promise<SignTxListParams>
  upgradePriceInfo(
    params: IUpgradePriceInfoParams
  ): Promise<IUpgradePriceInfoRes>
  dasBalancePay(params: IDasBalancePayParams): Promise<SignTxListParams>
  sendTrx(param: SignTxListRes): Promise<{ hash: string }>
  returnRegisteredPaymentTrxId(
    param: IReturnRegisteredPaymentTrxIdParams
  ): Promise<any>
  registeringAccounts(
    params: IRegisteringAccountParams
  ): Promise<{ registering_accounts: IAccountInfoRes[] }>
  myRewards(params: IMyRewardsParams): Promise<IMyRewardsRes>
  //auction
  auctionInfo(params: IAuctionInfoParams): Promise<IAuctionInfoRes>
  auctionPrice(params: IAuctionPriceParam): Promise<IAuctionPriceRes>
  auctionOrderStatus(
    params: IAuctionOrderStatusParam
  ): Promise<IAuctionOrderStatusRes>
  auctionPendingOrder(
    params: IAuctionPendingOrderParam
  ): Promise<IAuctionPendingOrderRes>
  auctionBid(params: IAuctionBidParam): Promise<IAuctionBidRes>
  recommendAccountList(
    prams: IRecommendAccountParams
  ): Promise<IRecommendAccountRes>
  editRecords(params: IEditRecordsParams): Promise<SignTxListParams>
  submitRenewOrder(
    params: ISubmitRenewOrderParams
  ): Promise<ISubmitRenewOrderRes>
  editOwner(params: IEditOwnerParams): Promise<IEditOwnerRes>
  dasLockEditOwner(params: IEditOwnerParams): Promise<IEditOwnerRes>
  editManager(params: IEditManagerParams): Promise<SignTxListParams>
}

export interface ICommon {
  tokens(): Promise<{ token_list: IToken[] }>
  config(): Promise<IConfig>
  upgradeNotice(): Promise<IUpgradeNoticeRes>
}

export interface IDasReverse {
  reverseInfo(params: IReverseInfoParams): Promise<IReverseInfoRes>
  setDasReverse(params: ISetDasReverseParams): Promise<SignTxListParams>
  deleteDasReverse(params: {
    key_info: IKeyInfo
    evm_chain_id: number
  }): Promise<SignTxListParams>
  searchAccounts(params: ISearchAccountsParams): Promise<ISearchAccountsRes>
  checkAccountStatus(
    params: ICheckAccountStatusParams
  ): Promise<ICheckAccountStatusRes>
  updateReverse(params: IUpdateReverseParams): Promise<IUpdateReverseRes>
  sendSignature(params: ISendSignatureParams): Promise<ISendSignatureRes>
  sendTx(params: SignTxListRes): Promise<ISendTxRes>
  checkReverseTxStatus(
    params: ICheckReverseTxStatusParams
  ): Promise<ICheckReverseTxStatusRes>
  reverseBatch(params: IReverseBatchParams): Promise<IReverseBatchRes>
}

export interface IServices {
  account: IAccountService
  common: ICommon
  dasReverse: IDasReverse
  didPoint: IDidPointService
  subAccount: ISubAccountService
}
