import React, { useEffect, useState } from 'react'
import { smartOpen, cn } from '@did/tools'
import { BaseCard } from '../card'
import { Iconfont } from '../icon-font'
import { AboutFaqType } from '../types/interface'

export const AboutFaq: AboutFaqType = ({
  title,
  message,
  faqMessage,
  className,
  localStorageKey,
  faqUrl
}) => {
  const [hide, setHide] = useState(false)

  const onClose = () => {
    window.localStorage.setItem(localStorageKey, 'true')
    setHide(true)
  }

  const onFaq = () => {
    faqUrl && smartOpen(faqUrl)
  }

  useEffect(() => {
    setHide(window.localStorage.getItem(localStorageKey) === 'true')
  }, [localStorageKey])

  return !hide ? (
    <BaseCard
      className={cn(
        'w-full shadow-sm border sm:p-6 p-4 rounded-3xl border-[#D0E8FF]',
        className
      )}
      style={{
        background: 'linear-gradient(0deg, #E8F4FF 0%, #E8F4FF 100%), #FFF'
      }}
    >
      <h2 className="flex justify-between items-center text-base font-semibold text-left mb-3 text-tip-font-color">
        {title}
        <span
          className="cursor-pointer p-1 inline-flex hover:opacity-70"
          onClick={onClose}
        >
          <Iconfont name="close" color="#B0B8BF" size="16" />
        </span>
      </h2>
      <div className="text-tip-font-color text-sm">{message}</div>
      {faqUrl && (
        <div className="flex items-center justify-start mt-3">
          <span
            className="text-link-font-color cursor-pointer py-1 font-medium text-sm hover:text-link-hover-font-color"
            onClick={onFaq}
          >
            {faqMessage}
          </span>
        </div>
      )}
    </BaseCard>
  ) : null
}
