import React, { CSSProperties, useEffect, useState } from 'react'
import styles from './style.module.scss'
import { IconfontType } from '@did/types/uikit'

export const Iconfont: IconfontType = ({
  name,
  color = '',
  size = 24,
  rounded = false,
  className,
  style: propsStyle,
  onClick
}) => {
  const [style, setStyle] = useState<CSSProperties>({
    width: `${size}px`,
    height: `${size}px`,
    minWidth: `${size}px`,
    minHeight: `${size}px`
  })
  useEffect(() => {
    setStyle({
      height: `${size}px`,
      width: `${size}px`,
      minWidth: `${size}px`,
      minHeight: `${size}px`,
      color: color || undefined,
      borderRadius: rounded ? `${size}px` : undefined
    })
  }, [size])

  if (!name) return <></>

  return (
    <svg
      key={`${name}-${size}`}
      className={`${styles.iconfont} ${className}`}
      style={{ ...style, ...propsStyle }}
      aria-hidden="true"
      onClick={onClick}
    >
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  )
}
