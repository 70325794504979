import React, { useState, useEffect, useRef } from 'react'
import { EllipsisTextType } from '../types/interface'
import { cn } from '@did/tools'
import { Tooltip } from '../tooltip'

export const EllipsisText: EllipsisTextType = ({ text, className }) => {
  const [displayText, setDisplayText] = useState(text)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && text) {
        const containerWidth = containerRef.current.offsetWidth - 80
        const charWidths = text.split('').map((char) => {
          const span = document.createElement('span')
          span.style.visibility = 'hidden'
          span.style.position = 'absolute'
          span.style.fontSize = '14px'
          span.style.fontWeight = '500'
          span.innerText = char
          document.body.appendChild(span)
          const width = span.offsetWidth
          document.body.removeChild(span)
          return width
        })

        let totalWidth = 0
        let start = 0
        let end = text.length - 1
        const ellipsisWidth = getCharWidth('...')

        while (
          start < end &&
          totalWidth + charWidths[start] + charWidths[end] + ellipsisWidth <
            containerWidth
        ) {
          totalWidth += charWidths[start] + charWidths[end]
          start++
          end--
        }

        if (start >= end) {
          setDisplayText(text)
        } else {
          setDisplayText(`${text.slice(0, start)}...${text.slice(end + 1)}`)
        }
      }
    }

    const getCharWidth = (char: string) => {
      const span = document.createElement('span')
      span.style.visibility = 'hidden'
      span.style.position = 'absolute'
      span.style.fontSize = '14px'
      span.style.fontWeight = '500'
      span.innerText = char
      document.body.appendChild(span)
      const width = span.offsetWidth
      document.body.removeChild(span)
      return width
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [text])

  return (
    <Tooltip message={<span className="break-all">{text}</span>}>
      <div ref={containerRef} className={cn('whitespace-nowrap', className)}>
        {displayText}
      </div>
    </Tooltip>
  )
}
