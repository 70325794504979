import { crc16 } from 'js-crc'
import React from 'react'

function makeCrcKey(key: string) {
  return key.length > 8 ? key.slice(0, 8) + '_' + crc16(key).slice(0, 3) : key
}

const noMatchArr = {}

export const buildTt = (translations: any, lang: string) => {
  return (key: string, param?: any) => {
    const crcKey = makeCrcKey(key)
    // if (!(crcKey in translations) && Object.keys(translations).length > 0) {
    //   if (!(crcKey in noMatchArr)) {
    //     // @ts-ignore
    //     noMatchArr[crcKey] = key
    //     console.log(JSON.stringify(noMatchArr))
    //   }
    // }

    let templateStr = translations[crcKey] || key

    if (!param) {
      return templateStr
    }

    // If param value is not react element, just replace and return string
    if (!Object.values(param).some((a) => React.isValidElement(a))) {
      Object.keys(param).forEach((v) => {
        templateStr = templateStr.replace(`{${v}}`, param[v])
      })
      return templateStr
    }

    const result: React.ReactElement[] = []

    let regex = /(\{[^{}]+})|([^{}]+)/g
    let parts: string[] = []
    let match
    while ((match = regex.exec(templateStr)) !== null) {
      parts.push(match[0])
    }

    parts.map((part) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const paramKey = part.slice(1, -1)
        const replacement = param[paramKey]

        if (React.isValidElement(replacement)) {
          result.push(
            React.cloneElement(replacement, { key: makeCrcKey(part) })
          )
        } else {
          result.push(
            <div style={{ display: 'inline' }} key={makeCrcKey(part)}>
              {replacement}
            </div>
          )
        }
      } else {
        result.push(
          <div style={{ display: 'inline' }} key={makeCrcKey(part)}>
            {part}
          </div>
        )
      }
    })
    return <>{result}</>
  }
}
