import { ColorSearchInputType } from '@did/types/uikit'
import { cn } from '@did/tools'
import React, { useCallback } from 'react'
import { useCompositionInput } from 'foxact/use-composition-input'

export const SearchInput: ColorSearchInputType = ({
  slots,
  buttonText,
  onSearch,
  searchLoading,
  showButton,
  value,
  placeholder,
  onChange,
  onCreate
}) => {
  return (
    <form
      className="w-full relative"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault()
        onSearch?.(value || '')
      }}
    >
      <input
        spellCheck="false"
        className="w-full h-[80px] md:h-[104px] rounded-full border-2 border-neutral-300 focus:border-emerald-400 hover:border-emerald-400 shadow-[0_0_0_8px] shadow-neutral-200 hover:shadow-emerald-400/10 focus:shadow-emerald-400/10 px-[72px] md:px-40 my-2 bg-white text-center outline-0 text-zinc-800 text-[28px] font-bold placeholder:font-medium placeholder:text-neutral-400"
        type="search"
        placeholder={placeholder}
        autoComplete="off"
        {...useCompositionInput(
          useCallback(
            (value: string) => {
              onChange?.(value)
            },
            [onChange]
          )
        )}
        defaultValue={value}
      />
      {value && (searchLoading || showButton) && (
        <slots.button
          className={cn(
            'absolute w-14 md:w-[128px] h-[56px] md:px-4 border-0 shadow-sm shadow-[#00000026] disabled:shadow-none right-3.5 md:right-6 top-[19px] md:top-8 bg-emerald-400 md:disabled:bg-zinc-700 md:disabled:bg-opacity-10 disabled:bg-transparent rounded-full'
          )}
          slots={slots}
          disabled={searchLoading}
          onClick={() => {
            onCreate?.()
          }}
        >
          {searchLoading ? (
            <slots.icon
              className="animate-rotate360Deg06s !w-5 !h-5 md:!w-6 md:!h-6"
              color="#525866"
              name="loading"
            />
          ) : (
            <>
              <span className="text-white text-2xl hidden md:inline-block">
                {buttonText}
              </span>
              <slots.icon
                className="!w-5 !h-5 rotate-180 !inline-block md:!hidden"
                color="#FFFFFF"
                name="back"
              />
            </>
          )}
        </slots.button>
      )}
    </form>
  )
}
