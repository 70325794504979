import { cn } from '@did/tools'

export function TabMeIcon(props: React.SVGProps<SVGSVGElement>) {
  const { className, ...rest } = props

  return (
    <svg
      className={cn(className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        data-follow-fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.429c-4.095 0-7.429-3.333-7.429-7.429s3.334-7.429 7.43-7.429c4.095 0 7.428 3.333 7.428 7.429S16.096 19.429 12 19.429M24 0H12C5.384 0 0 5.382 0 12c0 6.617 5.384 12 12 12 6.617 0 12-5.383 12-12 0-2.698-.896-5.191-2.405-7.197A5.992 5.992 0 0 0 24 0"
        fill="currentColor"
      />
    </svg>
  )
}
