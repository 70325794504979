import { BottomSheetType } from '@did/types/uikit'
import { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

export const BottomSheet: BottomSheetType = (param) => {
  const {
    open = false,
    title = '',
    onClose,
    slots,
    children,
    className
  } = param
  const [containerLayoutMaxHeight, setContainerLayoutMaxHeight] = useState('')

  const ref = useRef<any>()
  const [mounted, setMounted] = useState(false)
  const isHaveShowRef = useRef(false)
  const [animationing, setAnimationing] = useState(false)

  const containerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    ref.current = document.getElementsByTagName('body')[0]
    setMounted(true)
  }, [])

  useEffect(() => {
    if (open) {
      isHaveShowRef.current = true
    }
    if (isHaveShowRef.current) {
      containerRef.current?.classList.add('z-30')
      setAnimationing(true)
    }
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth
    const documentWidth = document.documentElement.offsetWidth
    const scrollBarWidth = viewportWidth - documentWidth
    setContainerLayoutMaxHeight(`${window.innerHeight * 0.9 - 92}px`)
    if (open) {
      document.documentElement.style.overflowY = 'hidden'
      document.documentElement.style.paddingRight = `${scrollBarWidth}px`
    } else {
      document.documentElement.style.overflowY = 'auto'
      document.documentElement.style.paddingRight = '0px'
    }
    return () => {
      document.documentElement.style.overflowY = 'auto'
      document.documentElement.style.paddingRight = '0px'
    }
  }, [open])

  const isMaskOn = useMemo(() => {
    return (
      open || animationing || containerRef.current?.classList.contains('z-30')
    )
  }, [open, animationing])

  // https://github.com/vercel/next.js/blob/canary/examples/with-portals/components/ClientOnlyPortal.js
  if (!mounted) return null

  return ReactDOM.createPortal(
    <div
      ref={(r) => (containerRef.current = r!)}
      onAnimationEnd={(e) => {
        if (!open) {
          e.currentTarget.classList.remove('z-30')
        }
        setAnimationing(false)
      }}
      className={`fixed top-0 left-0 flex justify-center cursor-default h-full w-screen -z-30    ${className} ${
        open
          ? 'animate-dialogShow'
          : isHaveShowRef.current
            ? 'animate-dialogHide'
            : ''
      }`}
    >
      <div
        className={`max-w-[680px] w-full self-end rounded-t-3xl overflow-hidden bg-white ${
          open
            ? 'animate-bottomSheetShow'
            : isHaveShowRef.current
              ? 'animate-bottomSheetHidden'
              : ''
        }`}
      >
        {isMaskOn && (
          <>
            <div className="relative flex justify-between items-center p-8">
              <span className="w-84 overflow-hidden overflow-ellipsis whitespace-nowrap text-2xl font-medium text-black text-left leading-8">
                {title}
              </span>
              <span
                className="hover:bg-gray-100 rounded-full cursor-pointer absolute right-6 top-7 p-2 leading-4 "
                onClick={onClose}
              >
                {slots.icon && (
                  <slots.icon name="close" size="19" color="#A0A1AB" />
                )}
              </span>
            </div>
            <div
              className="overflow-y-auto scrollbar-none"
              style={{ maxHeight: containerLayoutMaxHeight }}
            >
              {children}
            </div>
          </>
        )}
      </div>
    </div>,
    ref.current
  )
}
