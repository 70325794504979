import { cn } from '@did/tools'

export function TabAliasIcon(props: React.SVGProps<SVGSVGElement>) {
  const { className, ...rest } = props

  return (
    <svg
      className={cn(className)}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        data-follow-fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.122.835A2.292 2.292 0 0 0 .83 3.127v13.75a2.292 2.292 0 0 0 2.292 2.291h13.75a2.292 2.292 0 0 0 2.291-2.291V3.127A2.292 2.292 0 0 0 16.872.835H3.122Zm0 1.146c-.633 0-1.146.513-1.146 1.146v6.875h16.042V3.127c0-.633-.513-1.146-1.146-1.146H3.122Zm7.864 10.104c0-.396.273-.67.67-.67.395 0 .669.274.669.67s-.274.67-.67.67-.67-.274-.67-.67Zm-5.723 4.87a.67.67 0 1 1-1.34 0 .67.67 0 0 1 1.34 0Zm3.166-3.774c-.366 0-.7.122-1.005.335V11.72a1.354 1.354 0 0 0-1.187 0v5.844c.365.183.822.183 1.187 0v-.243c.274.213.64.334 1.005.334a1.64 1.64 0 0 0 1.46-.852c.214-.365.305-.822.305-1.37 0-.548-.122-1.004-.304-1.37-.305-.547-.822-.882-1.461-.882Zm-.213 3.44c-.548 0-.792-.548-.792-1.218 0-.67.244-1.218.792-1.218s.791.61.791 1.218c0 .609-.243 1.217-.791 1.217Zm2.83.913v-4.231a1.354 1.354 0 0 1 1.188 0v4.23a1.355 1.355 0 0 1-1.188 0Zm4.566-.944h-.457a.338.338 0 0 1-.334-.335v-2.04h.76a1.026 1.026 0 0 0 0-.943h-.76v-1.096a1.354 1.354 0 0 0-1.187 0v1.066h-.67a1.032 1.032 0 0 0 0 .943h.67v2.435c0 .7.456.974 1.339.974.396 0 .64-.03.64-.03.151-.274.151-.67 0-.974ZM3.697 5.99a1.719 1.719 0 1 1 3.437 0 1.719 1.719 0 0 1-3.437 0Zm4.583 0a1.719 1.719 0 1 1 3.437 0 1.719 1.719 0 0 1-3.437 0Zm6.302-1.719a1.719 1.719 0 1 0 0 3.438 1.719 1.719 0 0 0 0-3.438Z"
        fill="currentColor"
      />
    </svg>
  )
}
