import { Elements } from '@stripe/react-stripe-js'
import { Stripe, StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import React, { useContext } from 'react'

export interface IStripeProviderContextValue {
  stripePromise: Promise<Stripe | null>
}

const StripeProviderContext = React.createContext<IStripeProviderContextValue>(
  {} as IStripeProviderContextValue
)

export const StripeProvider = StripeProviderContext.Provider

export const useStripeContext = () => useContext(StripeProviderContext)

// export const StripeProvider = ({
//   value
// }: {
//   value: IStripeProviderContextValue
// }) => {
//   const stripePromise = loadStripe(key)
//
//   const Provider: React.FC<{
//     options: StripeElementsOptions
//     children: React.ReactElement
//   }> = ({ children, options }) => {
//     const [clientSecret, setClientSecret] = React.useState<string>('')
//
//     return (
//       <StripeProviderContext.Provider value={{ clientSecret, setClientSecret }}>
//         {clientSecret && (
//           <Elements
//             stripe={stripePromise}
//             options={{ ...options, clientSecret: clientSecret! }}
//           >
//             {children}
//           </Elements>
//         )}
//       </StripeProviderContext.Provider>
//     )
//   }
//
//   return Provider
// }
