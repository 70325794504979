import { LangSwitcherType, LANGUAGE } from '@did/types/uikit'
import React, { useState } from 'react'
import styles from './style.module.scss'

export const LangSwitcher: LangSwitcherType = ({
  dark = false,
  slots,
  computedLanguage,
  switchLanguage,
  langList = [],
  className
}) => {
  const [optionsShowing, setOptionsShowing] = useState(false)

  const onShowOptions = () => {
    setOptionsShowing(!optionsShowing)
  }

  return (
    <span
      className={`${styles.langSwitcher} ${className}`}
      style={{ color: dark ? '#FFFFFF' : '#121314' }}
    >
      {langList?.map(
        (option) =>
          computedLanguage?.value === option.value && (
            <span
              key={option.value}
              className={styles.langSwitcher__item}
              onClick={onShowOptions}
            >
              <slots.icon
                className={styles.langSwitcher__language__icon}
                name={computedLanguage.value === LANGUAGE.en ? 'en' : 'cn'}
                size="18"
                color={dark ? '#FFFFFF' : '#121314'}
              />
              {option.symbol}
              <slots.icon
                className={styles.langSwitcher__language__arrowDown}
                name="arrow-right"
                size="10"
                color={dark ? '#FFFFFF' : '#5F6570'}
              />
            </span>
          )
      )}
      {optionsShowing && (
        <ul className={styles.langSwitcher__list}>
          {langList.map((option) => (
            <li
              key={option.value}
              className={styles.langSwitcher__list__item}
              onClick={(e) => switchLanguage?.(option)}
            >
              {option.icon && (
                <slots.icon
                  className={styles.langSwitcher__list__item__icon}
                  name={option.icon}
                  size="20"
                  color="#B0B8BF"
                />
              )}
              {option.text}
            </li>
          ))}
        </ul>
      )}
    </span>
  )
}
