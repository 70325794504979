import React from 'react'
import { IconItemType } from '@did/types/uikit'

export const IconItem: IconItemType = ({
  onClick,
  icon,
  text,
  outsite,
  slots
}) => {
  return (
    <div
      className="group flex items-center w-full justify-between h-full"
      onClick={onClick}
    >
      <div className="text-sm font-medium">
        <span className=" mr-3">{icon}</span>
        {text}
      </div>
      {outsite && (
        <slots.icon
          className=" opacity-0 group-hover:opacity-100"
          name="arrow-right-up"
          color="#5F6570"
          size="12"
        />
      )}
    </div>
  )
}
