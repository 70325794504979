import { PopUpsType } from '../types/interface'
import * as Toast from '@radix-ui/react-toast'
import { Iconfont } from '../icon-font'
import { cn } from '@did/tools'
import styles from './styles.module.scss'

export const PopUps: PopUpsType = ({
  open,
  title,
  children,
  swipeDirection,
  duration = 5000,
  onClose
}) => {
  return (
    <Toast.Provider swipeDirection={swipeDirection || 'up'} duration={duration}>
      <Toast.Root
        className={cn(
          'w-full flex flex-col bg-white p-4 rounded-xl shadow border border-gray-100 gap-2.5',
          styles.ToastRoot
        )}
        open={open}
        onOpenChange={(open: boolean) => {
          onClose()
        }}
      >
        <Toast.Title className="inline-flex justify-between items-center text-neutral-900 text-base font-semibold">
          {title}
          <span
            className="inline-flex p-1 cursor-pointer -mr-1 hover:opacity-70"
            onClick={() => {
              onClose()
            }}
          >
            <Iconfont name="close" color="#B0B8BF" size="16" />
          </span>
        </Toast.Title>
        <Toast.Description asChild>{children}</Toast.Description>
      </Toast.Root>
      <Toast.Viewport className="inline-flex fixed m-auto lg:top-[86px] top-[62px] left-0 right-0 w-96 max-w-[94vw] z-50" />
    </Toast.Provider>
  )
}
