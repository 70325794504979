import { DidAvatarType, IDidAvatarProps } from '@did/types/uikit'
import DidAvatarLine from '@did/das-assets/images/components/did-avatar-line.svg'
import { useMemo } from 'react'
import { cn, generateAvatarRandomNumber } from '@did/tools'

const themeList = [
  {
    bg: '#F9E9E9',
    color: '#DF8585'
  },
  {
    bg: '#F9E9F0',
    color: '#DF85AA'
  },
  {
    bg: '#F9E9F7',
    color: '#DF85D0'
  },
  {
    bg: '#F5E3F8',
    color: '#C963D7'
  },
  {
    bg: '#F0E3F8',
    color: '#AA63D7'
  },
  {
    bg: '#EAE3F8',
    color: '#8C63D7'
  },
  {
    bg: '#E4E3F8',
    color: '#6963D7'
  },
  {
    bg: '#E3E6F8',
    color: '#6375D7'
  },
  {
    bg: '#E3EBF8',
    color: '#638ED7'
  },
  {
    bg: '#E3EFF8',
    color: '#63A5D7'
  },
  {
    bg: '#E3F4F8',
    color: '#63C4D7'
  },
  {
    bg: '#E3F8F7',
    color: '#63D7D3'
  },
  {
    bg: '#E3F8F3',
    color: '#63D7BA'
  },
  {
    bg: '#E3F8EE',
    color: '#63D7A1'
  },
  {
    bg: '#E3F8E9',
    color: '#63D784'
  },
  {
    bg: '#E3F8E4',
    color: '#63D767'
  },
  {
    bg: '#E7F8E3',
    color: '#78D763'
  },
  {
    bg: '#EBF8E3',
    color: '#90D763'
  },
  {
    bg: '#EFF8E3',
    color: '#A3D763'
  },
  {
    bg: '#F3F8E3',
    color: '#BED763'
  },
  {
    bg: '#F8F7E3',
    color: '#D7D163'
  },
  {
    bg: '#F8F3E3',
    color: '#D7BC63'
  },
  {
    bg: '#F8EBE3',
    color: '#D79163'
  },
  {
    bg: '#F8E7E3',
    color: '#D77A63'
  },
  {
    bg: '#F8E3E3',
    color: '#D76363'
  }
]

export function DidAvatar(props: IDidAvatarProps) {
  const {
    account,
    size,
    className,
    lineClassName,
    lineStyle,
    textClassName,
    fontSize
  } = props
  const avatarSize = useMemo(
    () => ({
      width: `${size}px`,
      height: `${size}px`,
      minWidth: `${size}px`,
      minHeight: `${size}px`
    }),
    [size]
  )
  const avatarTheme = useMemo(
    () =>
      themeList[
        generateAvatarRandomNumber(account || '', themeList.length - 1)
      ] || {
        bg: 'rgba(255, 255, 255, 0.2)',
        color: 'rgba(255, 255, 255, 0.2)'
      },
    []
  )
  const avatarName = useMemo(() => account.slice(0, 1).toUpperCase(), [account])
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center rounded-full font-normal leading-none',
        className
      )}
      style={{
        ...avatarSize,
        backgroundColor: avatarTheme.bg,
        color: avatarTheme.color,
        fontSize: fontSize || '110%'
      }}
    >
      <span className={cn('font-rubik', textClassName)}>{avatarName}</span>
      {account && (
        <DidAvatarLine
          className={cn('mb-[-2px]', lineClassName)}
          style={{ fill: avatarTheme.color, ...lineStyle }}
        />
      )}
    </div>
  )
}
