import React from 'react'

export interface IBaseProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  ref?: any
}

export interface IBackButtonProps extends IBaseProps {
  onClick: () => void
}

export interface IBasePropsWithSlots extends IBaseProps {
  slots?: { [key: string]: React.FC<any> }
  ref?: any
}

export interface ICallback<S, F> {
  successCallback?: (param: S) => void
  failedCallback?: (param: F) => void
  cancleCallback?: () => void
}

export interface IFiatPayDialogProps extends IBaseProps, ICallback<any, any> {
  showing: boolean
  title: string
  onClose: () => void
  clientSecret: string
  successCallback: (event: any) => any
  orderId: string
  returnUrl: string
}

export type FiatPayDialogType = React.FC<IFiatPayDialogProps>
