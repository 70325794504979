import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { IBaseProps } from "@did/types";

interface DasAvatarProps extends IBaseProps {
  size?: number | string;
  avatar?: string;
  account?: string;
}

export const DasAvatar: React.FC<DasAvatarProps> = ({
  size = 30,
  avatar,
  className,
  account = "das.bit",
}) => {
  const [identicon, setIdenticon] = useState(
    `https://display.d.id/identicon/${account}`
  );

  useEffect(() => {
    setIdenticon(`https://display.d.id/identicon/${account}`);
  }, [account]);

  return (
    <img
      className={`${styles.dasAvatar} ${className}`}
      src={avatar || identicon}
      width={size}
      height={size}
      alt={account}
    />
  );
};

export default DasAvatar;
