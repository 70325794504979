import config from '@/config'
import '@/styles/globals.css'
import {
  DasBalanceContextProvider,
  useContextVal,
  DasAppConfigContextProvider,
  useConfigVal,
  CccContextProvider
} from '@did/das-app-context'
import { BaseLayout } from '@did/das-app-modules'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { ReactElement, useEffect } from 'react'
import { localeData } from '@did/das-app-constants/locales'
import { StripeProvider, loadStripe } from '@did/stripe'
import { init as monitoringInit } from '@did/monitoring'

if (process.env.NODE_ENV === 'production') {
  monitoringInit({
    dsn: config.sentryDsn,
    environment: config.isProdData ? 'production' : 'development',
    release: process.env.npm_package_version
  })
}

const stripePromise = loadStripe(config.stripeKey)

type AppPropsWithLayout = AppProps & {
  Component: any
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter()

  const contextVal = useContextVal({
    config: {
      isProdData: config.isProdData,
      baseURL: config.servicesApi,
      reverseApiUrl: config.reverseApi,
      walletconnectProjectId: config.walletconnectProjectId,
      didPointApiUrl: config.dpApiUrl,
      subAccountApiUrl: config.subAccountApiUrl,
      upgradeNoticeUrl: config.upgradeNoticeUrl,
      ...(config.isProdData
        ? {}
        : {
            headers: {
              ['CF-Access-Client-Secret']: config.cfAccessClientSecret,
              ['CF-Access-Client-Id']: config.cfAccessClientId
            }
          })
    },
    router,
    localesData: localeData,
    outSiteUrl: {
      dasBalanceUrl: config.dasBalanceUrl,
      didtopPageUrl: config.didtopPageUrl,
      topdidPageUrl: config.topdidPageUrl,
      identiconServeUrl: config.identiconServer,
      didHomePageUrl: config.didHomePageUrl
    },
    typeId: {
      dasLock: config.dasLockTypeId,
      omniLock: config.omniLockTypeId,
      joyidLock: config.joyidLockTypeId,
      nostrLock: config.nostrLockTypeId
    }
  })

  const configVal = useConfigVal({
    services: contextVal.services
  })

  useEffect(() => {
    //@ts-ignore
    import('@did/uikit/src/icon-font/iconpark.js')
  })

  const getLayout =
    Component.getLayout ??
    ((page: ReactElement) => <BaseLayout>{page}</BaseLayout>)

  return (
    <StripeProvider value={{ stripePromise }}>
      <DasBalanceContextProvider value={contextVal!}>
        <DasAppConfigContextProvider value={configVal!}>
          <CccContextProvider>
            {getLayout(<Component {...pageProps} />)}
          </CccContextProvider>
        </DasAppConfigContextProvider>
      </DasBalanceContextProvider>
    </StripeProvider>
  )
}
