import { TopNavType } from '@did/types/uikit'
import React, { useEffect, useState } from 'react'
import { cn } from '@did/tools'
import { Iconfont } from '../icon-font'
export const TopNav: TopNavType = ({
  slots,
  menus,
  Right,
  Logo,
  handleMenuClick,
  className,
  style,
  currPath
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', checkIfMobile)
    checkIfMobile()

    return () => window.removeEventListener('resize', checkIfMobile)
  }, [])

  return (
    <div className={cn('sticky top-0 left-0 z-10', className)} style={style}>
      <div className="h-[86px] flex justify-between items-center mx-6">
        {Logo}
        {menus && (
          <nav className="flex">
            {menus?.map((menu, index) =>
              menu.path ? (
                <slots.link
                  key={index}
                  className={cn(
                    'px-4 py-2 text-base font-semibold hover:opacity-70 lg:text-xl',
                    currPath?.includes(menu.path)
                      ? 'text-main-700'
                      : 'text-main-700/50'
                  )}
                  handleClick={() => {
                    handleMenuClick?.(menu?.path)
                  }}
                >
                  {menu.text}
                </slots.link>
              ) : (
                <a
                  key={index}
                  className={cn(
                    'px-4 py-2 text-base font-semibold hover:opacity-70 lg:text-xl text-soft-400 group'
                  )}
                  target="_blank"
                  href={menu.href}
                >
                  {menu.text}
                  <Iconfont
                    className="opacity-0 group-hover:opacity-100"
                    name="arrow-right-up"
                    color="#5F6570"
                    size="12"
                  />
                </a>
              )
            )}
          </nav>
        )}
        <div className="md:w-[30vw] inline-flex justify-end">
          <Right />
        </div>
      </div>
    </div>
  )
}
