import { useDasBalanceContext } from '@did/das-app-context'
import { FiatPayDialogType, IReportStripeErrorParams } from '@did/types'
import { Payment } from './payment'
import { Dialog } from '@did/uikit'
import useSWRMutation from 'swr/mutation'
import { StripeError } from '@stripe/stripe-js'

export const FiatPayDialog: FiatPayDialogType = ({
  showing,
  title,
  onClose,
  successCallback,
  clientSecret,
  orderId,
  returnUrl
}) => {
  const { tt, alert, services } = useDasBalanceContext()
  const { trigger } = useSWRMutation(
    '/v1/stripe/err',
    async (url, { arg }: { arg: IReportStripeErrorParams }) => {
      return await services.didPoint.reportStripeError(arg)
    }
  )

  return (
    <Dialog showing={showing} title={title} closeButton onClose={onClose}>
      <div className="pb-8">
        <Payment
          stripeElementOption={{ clientSecret, locale: 'en' }}
          stripePaymentOption={{
            options: {
              layout: {
                type: 'tabs',
                defaultCollapsed: false,
                radios: true
              },
              wallets: {
                applePay: 'auto'
              }
            }
          }}
          alert={alert}
          tt={tt}
          returnURL={returnUrl}
          successCallback={successCallback}
          onLoadError={(error: StripeError) => {
            if (!error || !orderId) {
              return
            }
            trigger({ ...error, order_id: orderId })
          }}
        />
      </div>
    </Dialog>
  )
}
