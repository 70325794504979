import React, { InputHTMLAttributes } from "react";
import styles from "./styles.module.scss";
import { cn } from "@did/tools";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  type?: string;
  suffix?: string;
  errorMessages?: string[];
  label?: string;
}

export type TextInputType = React.FC<TextInputProps>;

export const StripeInput: TextInputType = React.forwardRef<
  HTMLInputElement,
  TextInputProps
>(
  (
    {
      label,
      type = "text",
      suffix = "",
      errorMessages = [],
      className,
      ...props
    },
    ref
  ) => {
    return (
      <div className={className}>
        {label && <span className={styles["stripe-input-label"]}>{label}</span>}
        <div className="">
          <input
            ref={ref}
            className={cn(styles["stripe-input"], {
              [styles["stripe-input-error"]]: errorMessages?.length,
            })}
            type={type}
            {...props}
          />
          <span className="">{suffix}</span>
        </div>
        {errorMessages[0] && (
          <div className={styles["stripe-input-error-msg"]}>
            {errorMessages[0]}
          </div>
        )}
      </div>
    );
  }
);
