import { cn } from "@did/tools";

export function PersonIcon(props: React.SVGProps<SVGSVGElement>) {
  const { className, ...rest } = props;

  return (
    <svg
      className={cn(className)}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.82813 14.5859C1.375 14.5859 1.01823 14.4818 0.757813 14.2734C0.502605 14.0703 0.375001 13.7891 0.375001 13.4297C0.375001 12.9297 0.528647 12.4036 0.835938 11.8516C1.14323 11.2995 1.58594 10.7813 2.16406 10.2969C2.74219 9.8125 3.4375 9.42188 4.25 9.125C5.06771 8.82292 5.98177 8.67188 6.99219 8.67188C8.00781 8.67188 8.92188 8.82292 9.73438 9.125C10.5521 9.42188 11.2474 9.8125 11.8203 10.2969C12.3984 10.7813 12.8411 11.2995 13.1484 11.8516C13.4609 12.4036 13.6172 12.9297 13.6172 13.4297C13.6172 13.7891 13.487 14.0703 13.2266 14.2734C12.9714 14.4818 12.6172 14.5859 12.1641 14.5859H1.82813ZM7 7.28906C6.42188 7.28906 5.89323 7.13542 5.41406 6.82813C4.9349 6.52083 4.54948 6.10417 4.25781 5.57813C3.97135 5.05208 3.82813 4.46615 3.82813 3.82031C3.82813 3.1849 3.97135 2.60938 4.25781 2.09375C4.54948 1.57813 4.9349 1.16927 5.41406 0.867188C5.89844 0.559897 6.42708 0.406251 7 0.406251C7.57292 0.406251 8.09896 0.557292 8.57813 0.859376C9.05729 1.15625 9.44271 1.5625 9.73438 2.07813C10.026 2.59375 10.1719 3.16927 10.1719 3.80469C10.1719 4.46094 10.026 5.05208 9.73438 5.57813C9.44792 6.10417 9.0625 6.52083 8.57813 6.82813C8.09896 7.13542 7.57292 7.28906 7 7.28906Z"
        fill="currentColor"
      />
    </svg>
  );
}
