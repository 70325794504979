import React, { ButtonHTMLAttributes } from 'react'
import { IBaseProps, IBasePropsWithSlots } from '../components'

export enum LANGUAGE {
  en = 'en',
  zhCN = 'zh-CN'
}

export interface ILanguageOption {
  text: string
  symbol: string
  value: LANGUAGE
  matcher: RegExp
  icon?: string
}

export interface IButtonProps extends IBasePropsWithSlots {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  Icon?: IconfontType
  size?: 'small' | 'middle' | 'larger'
  disabled?: boolean
  status?: 'loading' | 'success' | 'default' | 'gray'
  type?: 'primary' | 'default' | 'text'
  full?: boolean
  slots: {
    icon: IconfontType
  }
}

export type ButtonType = React.FC<IButtonProps>

// @ts-ignore
export interface IDasButtonProps
  extends IBasePropsWithSlots,
    ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean
  isLoadingGradient?: boolean
  loading?: boolean
  done?: boolean
  primary?: boolean
  second?: boolean
  black?: boolean
  status?: string
  solid?: boolean
  small?: boolean
  defaultLoading?: boolean
  middle?: boolean
  large?: boolean
  to?: string
  href?: string
  target?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  slots: {
    icon: IconfontType
  }
}

export type DasButtonType = React.FC<IDasButtonProps>

export interface IIconItemProps extends IBasePropsWithSlots {
  onClick?: () => void
  icon?: React.ReactNode
  text?: string
  outsite?: boolean
  slots: {
    icon: IconfontType
  }
}

export type IconItemType = React.FC<IIconItemProps>

export interface ISelectProps extends IBaseProps {
  options?: Array<{
    label: string | React.ReactNode
    value: string
    type?: 'menu' | 'separator'
  }>
  onChange?: (value: string) => void
}

export type SelectType = React.FC<ISelectProps>

export interface IAlertProps extends IBaseProps {
  key?: string // Prevent duplicate pop-ups
  title: string
  message: string | React.ReactNode
  actionButtonText?: string
  onAction?: () => void
  enableCloseAction?: boolean
  closeButton?: boolean
}

export interface IConfontProps extends IBaseProps {
  name?: string
  color?: string
  size?: number | string
  rounded?: boolean
  onClick?: () => void
}

export type IconfontType = React.FC<IConfontProps>

export interface IDialogProps extends IBasePropsWithSlots {
  showing?: boolean
  title?: string
  titleSize?: number
  message?: string | React.ReactNode
  actionButtonText?: string
  closeButton?: boolean
  enableCloseAction?: boolean
  onClose?: () => void
  action?: React.ReactNode
  onAction?: () => void
  dialogClassname?: string
  slots: {
    button: any // DasButtonType/ButtonType
    icon?: IconfontType
  }
}

export type DialogType = React.FC<IDialogProps>

export interface ILinkProps extends IBaseProps {
  handleClick?: () => void
}

export type LinkType = React.FC<ILinkProps>

export interface ITopNavMenu {
  path?: string
  href?: string
  text: string | React.ReactNode
  iconName?: string
  iconComponent?: (props: React.SVGProps<SVGSVGElement>) => any
}

export interface ITopNavProps extends IBasePropsWithSlots {
  slots: {
    link: LinkType
    icon: IconfontType
  }
  currPath?: string
  menus?: ITopNavMenu[]
  handleMenuClick?: (path?: string) => void
  Right: React.FC<IBaseProps>
  Logo: React.ReactNode
}

export type TopNavType = React.FC<ITopNavProps>

export interface ILangSwitcherProps extends IBasePropsWithSlots {
  dark?: boolean
  langList: ILanguageOption[]
  switchLanguage?: (option: ILanguageOption) => void
  computedLanguage?: ILanguageOption
  slots: {
    icon: IconfontType
    select: SelectType
  }
}

export type LangSwitcherType = React.FC<ILangSwitcherProps>

export interface IConnectWalletProps extends IBasePropsWithSlots {
  slots: {
    button: DasButtonType
    icon: IconfontType
  }
  chainIconName: string
  address: string
  onClick?: () => void
  empty?: React.ReactNode
  bitName?: string
}

export type ConnectWalletType = React.FC<IConnectWalletProps>

export interface IColorSearchInputProps extends IBasePropsWithSlots {
  slots: {
    button: ButtonType
    icon: IconfontType
  }
  value?: string
  buttonText?: string
  onChange?: (value: string) => void
  onSearch?: (value: string) => void
  onCreate?: () => void
  searchLoading?: boolean
  showButton?: boolean
  placeholder?: string
}

export type ColorSearchInputType = React.FC<IColorSearchInputProps>

export interface ITextInputProps extends IBasePropsWithSlots {
  onChange?: (value: string) => void
  value?: string
  placeholder?: string
  disabled?: boolean
  suffix?: React.ReactNode | string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  errorMessages?: string[]
}

export type TextInputType = React.FC<ITextInputProps>

export interface IBottomSheetProps extends IBasePropsWithSlots {
  open?: boolean
  onClose?: () => void
  title?: string | React.ReactNode
  bgImage?: string
  fillScreen?: boolean
  slots: {
    icon?: IconfontType
  }
}

export type BottomSheetType = React.FC<IBottomSheetProps>

export interface IIconImageProps extends IBaseProps {
  url?: string
  alt?: string
  size?: number
  rounded?: boolean
  onClick?: () => void
}

export type IconImageType = React.FC<IIconImageProps>

export interface ICheckBoxProps extends IBasePropsWithSlots {
  checked?: boolean
  onChange?: (checked: boolean) => void
  slots: {
    icon: IconfontType
  }
}

export type CheckBoxType = React.FC<ICheckBoxProps>

export interface IBaseCardProps extends IBaseProps {
  status?: 'warning' | 'normal'
}
export type BaseCardType = React.FC<IBaseCardProps>

export interface IDidCardProps extends IBaseProps {
  account: string
  width?: string
  onLoaded?: () => void
}
export type DidCardType = React.FC<IDidCardProps>

export interface IDidAvatarProps extends IBaseProps {
  account: string
  size: number
  lineClassName?: string
  lineStyle?: React.CSSProperties
  textClassName?: string
  fontSize?: string
}
export type DidAvatarType = React.FC<IDidAvatarProps>
