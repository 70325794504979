import React from 'react'
import { ButtonType } from '@did/types/uikit'
import { cn } from '@did/tools'

export const IconButton: ButtonType = React.forwardRef(
  ({ children, className, Icon, onClick, size = 'middle' }, ref) => {
    return (
      <button
        ref={ref as any}
        className={cn(
          'border bg-white border-gray-100 flex justify-center items-center gap-x-1 rounded-lg cursor-pointer hover:bg-gray-100 active:bg-gray-200',
          {
            ['px-3 h-9']: size === 'middle',
            ['px-1 h-6']: size === 'small'
          },
          className
        )}
        onClick={onClick}
      >
        {Icon && <Icon size={16} />}
        {children}
      </button>
    )
  }
)
