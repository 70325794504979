import React, { useState, useEffect, useMemo } from 'react'
import { IconImageType } from '@did/types/uikit'

enum Mode {
  Img = 'img',
  FirstChar = 'firstChar'
}

interface FirstChar {
  color: string
  text: string
}

export const IconImage: IconImageType = ({
  url,
  alt,
  size = 24,
  rounded = false,
  className
}) => {
  const [imgUrl, setImgUrl] = useState('')
  const [mode, setMode] = useState(Mode.FirstChar)
  const colorMap: { [key: string]: string } = {
    A: '#AC7549',
    B: '#679781',
    C: '#AB68B6',
    D: '#9B9FBA',
    E: '#6A7BDE',
    F: '#1B2C89',
    G: '#4C4CAC',
    H: '#C74331',
    I: '#D28F48',
    J: '#F7D09A',
    K: '#1ABB9C',
    L: '#9D8562',
    M: '#706460',
    N: '#654E3A',
    O: '#9E9E9E',
    P: '#795548',
    Q: '#FE5821',
    R: '#FE9800',
    S: '#E3443A',
    T: '#F6946E',
    U: '#679781',
    V: '#4CAE51',
    W: '#009688',
    X: '#07BBD3',
    Y: '#08A9F3',
    Z: '#2196F2'
  }

  const firstChar: FirstChar = (() => {
    const _firstChar: string = alt?.[0] || ''
    if (_firstChar && colorMap[_firstChar.toUpperCase()]) {
      return {
        text: _firstChar,
        color: colorMap[_firstChar.toUpperCase()]
      }
    } else {
      return {
        text: _firstChar || '',
        color: '#3F52B4'
      }
    }
  })()

  useEffect(() => {
    if (!url) return
    let _url = url
    if (!_url.startsWith('http') && process.env.NEXT_PUBLIC_CDN_PATH) {
      _url = `${process.env.NEXT_PUBLIC_CDN_PATH}${_url}`
    }
    const img = new Image()
    img.src = _url

    img.onload = () => {
      setMode(Mode.Img)
      setImgUrl(_url)
    }

    img.onerror = () => {
      setMode(Mode.FirstChar)
    }
  }, [url])

  const containerStyle: React.CSSProperties = useMemo(() => {
    let style: React.CSSProperties = {
      height: `${size}px`,
      width: `${size}px`
    }

    if (mode === Mode.FirstChar) {
      style = {
        ...style,
        fontSize: `${size * 0.5}px`,
        lineHeight: `${size}px`
      }

      if (firstChar.text) {
        style = {
          ...style,
          background: firstChar.color
        }
      }
    }

    if (rounded && size) {
      style = {
        ...style,
        borderRadius: `${size}px`
      }
    }

    return style
  }, [mode])

  return (
    <>
      {mode === Mode.FirstChar ? (
        <span
          className={` inline-block rounded-full font-medium uppercase text-white text-center ${className}  `}
          style={containerStyle}
          {...{ alt }}
        >
          {firstChar.text}
        </span>
      ) : (
        <img
          className={`inline-block rounded-full  ${className} `}
          src={imgUrl}
          draggable={false}
          style={containerStyle}
          {...{ alt }}
        />
      )}
    </>
  )
}

export default IconImage
