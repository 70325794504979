import { Decimal } from ".";

const BIT_ARR = [
  BigInt("999989423469314432"),
  BigInt("999978847050491904"),
  BigInt("999957694548431104"),
  BigInt("999915390886613504"),
  BigInt("999830788931929088"),
  BigInt("999661606496243712"),
  BigInt("999323327502650752"),
  BigInt("998647112890970240"),
  BigInt("997296056085470080"),
  BigInt("994599423483633152"),
  BigInt("989228013193975424"),
  BigInt("978572062087700096"),
  BigInt("957603280698573696"),
  BigInt("917004043204671232"),
  BigInt("840896415253714560"),
  BigInt("707106781186547584"),
];

const PRECISION = BigInt("1000000000000000000");
export const StartPremium = BigInt("100000000");

export function premiumEns(param: {
  expires: bigint;
  nowTime: bigint;
  totalDays: bigint;
  gracePeriod: bigint;
}) {
  let { expires, nowTime, totalDays, gracePeriod } = param;
  let endValue = StartPremium >> totalDays;
  expires = expires + gracePeriod;
  if (expires > BigInt(Math.floor(Date.now() / 1000))) {
    return StartPremium;
  }
  let elapsed = nowTime - expires;
  let premium = decayedPremium(elapsed);
  if (premium >= endValue) {
    return premium - endValue;
  }
  return BigInt(0);
}

function decayedPremium(elapsed: any) {
  let elapsedBI = BigInt(elapsed);
  let daysPastBI = (elapsedBI * PRECISION) / (BigInt("24") * BigInt("3600"));
  let initDaysBI = daysPastBI / PRECISION;
  let premiumBI = StartPremium >> initDaysBI;
  let partDayBI = daysPastBI - initDaysBI * PRECISION;
  let temp = BigInt("2") ** BigInt("16");
  let fractionBI = (partDayBI * temp) / PRECISION;
  let totalPremium = addFractionalPremium(fractionBI, premiumBI);
  return totalPremium;
}

function addFractionalPremium(fraction: any, premium: any) {
  let temp, res;
  for (let i = 0; i < 16; i++) {
    temp = BigInt("1") << BigInt(i);
    res = fraction & temp;
    if (res !== BigInt(0)) {
      premium = (premium * BIT_ARR[i]) / PRECISION;
    }
  }
  return premium;
}

export function premium(param: {
  expires: Decimal;
  nowTime: Decimal;
  totalDays: Decimal;
  gracePeriod: Decimal;
}) {
  let { expires, nowTime, totalDays, gracePeriod } = param;

  if (nowTime.gt(expires.add(totalDays.times(3600 * 24)))) {
    return 0;
  }

  return new Decimal(10)
    .pow(8)
    .dividedBy(new Decimal(2).pow(nowTime.minus(expires).dividedBy(3600 * 24)));
}
